import * as React from 'react';
import { FunctionComponent } from "react";
import { WebSiteProps } from '../../../browser/Browser';
import { BrowserApi } from '../../../browser/BrowserApi';

const FatecHome: FunctionComponent<WebSiteProps> = ({
    browserApi,
    url
}) => {
    React.useEffect(() => {
        browserApi.setTitle('Fatec Homepage')
    }, []);

    return (
        <div>
            <h1>Homepage Fatec</h1>
            <hr />

            <p>Bem vindo a pagina inicial da Faculdade de Tecnologia.</p><br /><br />
            <h2><a href="#">Noticias</a></h2>
            <p>Ver as noticias da Fatec</p>
            <hr />
            <h2><a href="#">Comunidade</a></h2>
            <p>Paginas de alunos e professores hospedado no servidor WWW da Fatec</p>
        </div>
    );
};

export default FatecHome;