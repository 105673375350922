import { EventType } from "./events";

/**
 * Errors
 */
export class FileNotFoundError extends Error {}
export class IsDirectoryError extends Error {}
export class FileAlreadyExistsError extends Error {}

/**
 * Events
 */
export type FileEvent = EventType | {
    path: string
}

export  type FileWrited = FileEvent | Event | {
    content: string
};
export type FileRead = EventType | Event;
export type FileRemoved = Event | FileEvent;
export type DirectoryRemoved = Event | FileEvent;
export type FileMoved = EventType | FileEvent | {
    origin: string
}
export type FileCopied = EventType | FileEvent | {
    origin: string
}

export enum FileEvents {
    FileWrited = 'file_writed',
    FileRead = 'file_read',
    FileRemoved = 'file_removed',
    DirectoryRemoved = 'directory_removed',
    FileMoved = 'file_moved',
    FileCopied = 'file_copied'
}

export interface Filesystem {
    read(path: string): Promise<string>;
    write(path: string, content: string): Promise<boolean>;
    delete(path: string, recursive: boolean): Promise<boolean>;
    move(path: string, origin: string): Promise<boolean>;
    copy(path: string, origin: string, force: boolean): Promise<boolean>;
    isDirectory(path: string): Promise<boolean>;
    fileExists(path: string): Promise<boolean>;
}