import TerminalApi from "../../terminal/TerminalApi";
import websites from "../websites/websites";
import { changeScene } from "../actions";
import { Scene } from "../scene";

export default class {
    constructor(private t: TerminalApi) {}

    async run() {
        await this.t.openBrowser(websites, 'bemvindo');
        this.t.setTerminalHelp(`
            <h2>Abrir o browser do terminal</h2><br />
            <p>Para abrir o browser a partir do terminal, 
               basta digitar <code>browser</code>.</p>
            <br /><br />
            Vá em frente, e abra o browser.
        `)

        this.t.clear();

        let command;
        do {
            command = await this.t.input();

            if (!command.match(/browser/g)) {
                this.t.print('Para abrir o browser, o comando precisa ser browser');
            }
        } while(!command.match(/browser/g));
        
        await this.t.openBrowser(websites, 'bemvindo/tutorial/final.html');

        this.t.dispatch(changeScene(Scene.HOMEWORK_ONE));
        
        return this.t.done();
    }
}