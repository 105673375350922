import TerminalApi from "../../terminal/TerminalApi";
import * as firebase from 'firebase';

export type Progress =  {
    title: string,
    goals: {
        doing: string,
        todo: Array<string>,
        completed: Array<string>
    }
};

export type Goals = {
    [key: string]: string
};

export abstract class Challenge {
    protected progress: Progress|null = null;

    constructor(protected t: TerminalApi) { }

    setCurrentProgress(progress: Progress) {
        this.progress = progress;
        this.setTerminalChallange();
    };

    getCurrentProgress(): Progress {
        if (!this.progress) {
            throw new Error('Progresso não foi definido.');
        }

        return this.progress;
    }

    getCurrentGoal(): string {
        if (!this.progress) {
            throw new Error('Progresso não foi definido.');
        }

        return this.progress.goals.doing;
    }

    completeGoal(goal: string): boolean {
        if (this.getCurrentGoal() != goal || !this.progress) return false;
        this.progress.goals.completed.push(goal);
        this.progress.goals.doing = this.progress.goals.todo.shift() || '';

        firebase
            .firestore()
            .collection('users')
            .doc(`${firebase.auth().currentUser?.uid}/challenges/${this.getName()}`)
            .update(this.progress);

        this.setTerminalChallange();
        return true;
    }

    setTerminalChallange() {
        if (!this.progress) return;

        this.t.setChallenge({
            ...this.progress,
            goalsDetails: false,
            helpCallback: this.helpCallback.bind(this)
        });
    }

    abstract helpCallback(): Promise<any>;
    abstract getName(): string;
};

export const createChallenge = async(challangeObj: {new(...args: any[]): Challenge}, t: TerminalApi) => {
    const challange = new challangeObj(t);
    const ref = await firebase
        .firestore()
        .collection('users')
        .doc(`${firebase.auth().currentUser?.uid}/challenges/${challange.getName()}`);

    const currentProgress = await ref.get();

    if (currentProgress.exists) {
        challange.setCurrentProgress((currentProgress.data() as Progress));
        return challange;
    }

    await ref.set(challange.getCurrentProgress());
};