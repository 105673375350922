import TerminalApi from "../../terminal/TerminalApi";
import events from "../../core/events";
import { FileEvents, FileWrited } from "../../core/filesystem";
import { createChallage, HomeworkOneGoals, HomeworkOneChallege } from "../challenges/HomeworkOne";
import { FilesystemFirebase } from "../../core/filesystem.firebase";
import settings from "../../shared/settings";
import { changeScene } from "../actions";
import { Scene } from "../scene";

export class HomeworkOne {
    private challenge: HomeworkOneChallege|null = null;

    constructor(private t: TerminalApi) {}

    async run() {
        await this.t.print("Starting system...", true, {
            color: 'green',
            blink: true
        });

        this.challenge = await createChallage(this.t, this.helpChallenge.bind(this));
        this.challangeEvents();

        this.t.clear();

        if (this.challenge.getCurrentGoal() == HomeworkOneGoals.INSTRUCTION) {
            this.t.setTerminalHelp(`
                <h2>Trabalho da faculdade</h2>

                <p>
                    Seu professor de progamação passou um trabalho para turma,
                    você precisa fazer e mandar por e-mail para o professor.
                    Veja abaixo quais foram as anotações que você fez da explicação
                    do trabalho passado pelo professor.
                </p>

                <br />
                <h3> Aula de programação </h3>
                <strong>Trabalho semestral</strong><br />
                <strong>Criando a própia homepage</strong>
                <p>
                    <ul>
                        <li>Criar uma homepage em HTML falando sobre mim.</li>
                        <li>Enviar o aquivo na minha home do FTP da Faculdade.</li>
                    </ul>
                </p>
                <br />
                <p>
                    Você salvou as informações de FTP e seus acessos na sua home
                    na pasta ~/faculdade.
                </p>
                
                <a data-command="continue" class="command">Continuar</a>
            `);

            let input;
            do {
                input = await this.t.input();

                if (input !== "continue") {
                    this.t.print(`
                        Antes de continuar, leia as instruções ao lado e 
                        clique em Continuar.
                    `);
                }
            } while(input !== "continue");

            this.t.clear();



            this.t.setTerminalHelp(`
                <h2>Um novo desafio</h2>

                <p>
                    Para esse desafio, ele será considerado concluido quando você
                    subir o arquivo no FTP.
                </p>
                <br /><br />
                <a data-command="start" class="command">Começar desafio!</a>
            `);

            do {
                input = await this.t.input();

                if (input !== "start") {
                    this.t.print(`
                        Antes de continuar, leia as instruções ao lado e 
                        clique em Começar desafio! 
                    `);
                }
            } while(input !== "start");

            this.t.setTerminalHelp(null);

            (new FilesystemFirebase()).write(`${this.t.getUserHome()}/faculdade/ftp_credenciais.txt`, `
Host: alunos.fatec.edu
Usuário: ${this.t.getUsername()}
Senha: senha@42
            `);

            this.challenge.completeGoal(HomeworkOneGoals.INSTRUCTION);
        }

        this.t.clear();

        return this.t.done();
    }

    challangeEvents() {
        if (!this.challenge) return;
        events.subscribe(FileEvents.FileWrited, <FileWrited>(event: any) => {
            if (event.path.match(/(.*)alunos.fatec.edu(.*)\.(html|htm)/g)) {
                if (event.path.match(/home\.(html|htm)/)) {
                    this.challenge?.completeGoal(HomeworkOneGoals.SEND_FTP);
                    this.t.setChallenge(null);
                    this.t.dispatch(changeScene(Scene.HOMEWORK_ONE_DONE));
                    return;
                }

                this.t.setTerminalHelp(`
                    <h2>Ops!</h2>

                    <h3>Desafio não foi concluido ainda.</h3>
                    <p>
                        Parece que você subiu um html para o FTP, porém conforme as
                        instruções do desafio, o nome do arquivo deveria ser home.html
                    </p>

                    <a data-close="true" class="command">Fechar dica</a>
                `);
                return;
            }
            
            if (event.path.match(/\.(html|htm)/) && event.content) {
                if (!this.challenge) return;
                if (!event.path.match(/home\.(html|htm)/)) {
                    this.t.setTerminalHelp(`
                        <h2>Dica: home.html</h2>

                        <p>
                            Lembre-se que o nome do arquivo que você vai enviar para o FTP,
                            deve-se chamar home.html
                        </p>

                        <a data-close="true" class="command">Fechar dica</a>
                    `);
                    return;
                }

                this.challenge.completeGoal(HomeworkOneGoals.CREATE_HTML);
            }
        });

        events.subscribe('command_executed', async(event: any) => {
            if (event.command == "ftp" && !event.input.match(/alunos.fatec.edu/g)) {
                this.t.setTerminalHelp(`
                    <h2>Dica: Credenciais</h2>

                    <p>
                        Lembre-se que você salvou o nome de usuário e senha 
                        em um diretorio chamado faculdade na sua home.
                    </p>

                    <a data-close="true" class="command">Fechar dica</a>
                `);
            }
        });

        events.subscribe('start_edit_file', (event: any) => {
            if (!event.file.match(/(.html|htm)/)) {
                return;
            }

            this.t.setFileHelp(`
                <h2>Lembrete: Todos irão ver!</h2>

                <p>
                    Crie um arquivo HTML falando sobre você, seja livre para
                    falar o que quiser (e não precisa ser sobre o seu personagem, pode
                    ser sobre você na vida real)
                </p>

                <h3>Ficará publico!</h3>
                <p>
                    Lembre-se que seu arquivo ficara publico para outros usuários
                    que acessarem o site da faculdade pelo browser.
                    <strong>Caso não queira criar uma pagina HTMl, escreva apenas
                    N/A e salve o arquivo para continuar o desafio.</strong>
                </p>

                <h3>Links</h3>
                <p>
                    Os href iram procurar por sites ou .html dentro do ecosistema do ${settings.project.name}.
                </p>

                <h3>HTML de 1994</h3>
                <p>
                    Lembre-se que o seu HTML não tera recursos mordenos como o HTML5,
                    use <a href='http://info.cern.ch/hypertext/WWW/MarkUp/Tags.html' target="_blank">este link do WWW</a>
                    para utilizar as tags.
                </p>

                <h3>Não quer escrever o HTML?</h3>
                <p>
                    Caso você não quiser criar o arquivo de HTML agora,
                    apenas coloque N/A e salve o arquivo.
                </p>

                <h3>Atenção aos termos de uso.</h3>
                <p>
                    Não coloque nada ofensivo, não faça link para outro site ou iframes (
                    serão automaticamente bloqueados).
                    
                    Quebrar os termos de uso e privacidade pode causar o banimento de sua conta.
                </p>

                <a data-close="true" class="command">Entendido.</a>
            `);
        });

    }  

    async helpChallenge() {
        if (!this.challenge) return;

        if (this.challenge.getCurrentGoal() == HomeworkOneGoals.CREATE_HTML) {
            this.t.setTerminalHelp(`
                <h2>Criando o HTML</h2>

                <p>
                    Você precisa criar um arquivo HTML e falar sobre você,
                    sinta-se livre para falar o que quiser, lembre-se que este HTMl
                    firacara disponível para outros usuários.
                </p>

                <h3>Criando arquivos</h3>
                <p>
                    Para criar um arquivo, basta digitar "edit nomedoarquivo", <br />
                    Exemplo: edit home.html
                </p>

                <h3>Onde criar o arquivo?</h3>
                <p>
                    Você pode criar o arquivo onde você quiser, lembre-se que depois que você
                    criar o seu arquivo, você irá subir ele para ftp usando o comando "ftp".
                </p>

                <a data-close="true" class="command">Fechar ajuda</a>
            `);
            return;
        }

        if (this.challenge.getCurrentGoal() == HomeworkOneGoals.SEND_FTP) {
            this.t.setTerminalHelp(`
                <h2>Enviando para FTP</h2>

                <p>
                    Agora que você já criou o arquivo HTML, você precisa enviar ele para o
                    servidor de FTP da faculdade.<br />
                    Basta colocar o arquivo home.html na pasta home no servidor de ftp.
                </p>

                <h3>Qual é o servidor e as credenciais?</h3>

                <p>
                    Você salvou os dados de conexão com o servidor na sua pasta home,
                    dentro do diretorio faculdade.
                </p>

                <h3>Como usar o comando FTP?</h3>
                <p>
                    O comando "ftp" foi baseado no comando cliente ftp cli, se você
                    nunca subiu um arquivo usando ftp cli nativo, não é tão dificil assim,
                    veja as instruções abaixo:<br />
                    <ul>
                        <li>Conecte-se no servidor usando "ftp nomedoservidor"</li>
                        <li>Depois de conectado, você pode digitar ? para ver quais comandos você pode usar</li>
                        <li>Use o comando put para enviar o arquivo da sua maquina. (use lpwd para ver em qual
                            diretorio você está na maquina, caso precise, utilize o lcd para mudar ou lls para listar
                            os diretorios da sua maquina local. ls/cd/pwd irá executar no servidor de ftp, veja
                                mais digita ? depois de conectado no servidor)</li>
                    </ul>
                </p>

                <a data-close="true" class="command">Fechar ajuda</a>
            `);
        } 
        
    }
}