import * as React from 'react';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { changeScene } from './actions';
import { Scene, sceneRender } from './scene';
import { useStore, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Terminal from '../terminal/Terminal';

type Props = {
    freestyle?: boolean
};

const Game: React.FunctionComponent<Props> = ({
    freestyle = false
}) => {
    const [scene, setScene] = React.useState<Scene|null>(null);
    const [auth, profile, isLoading]: any = useSelector<any>(state => ([
        state.firebase.auth,
        state.firebase.profile,
        state.game.isLoading
    ]));
    const history = useHistory();

    React.useEffect(() => {
        if (!auth.isEmpty && profile.isLoaded) {
            if (!profile.alphaKey) {
                history.replace('/');
                return;
            }
            setScene(profile.scene || Scene.WELCOME);
        }

    }, [profile, auth]);

    React.useEffect(() => {
        if (auth.isLoaded && auth.isEmpty) {
            setScene(Scene.LOGIN);
        }
    }, [auth]);

    if (!scene || isLoading || !auth.isLoaded || !profile.isLoaded) {
        return (
            <p>Carregando...<span className="cursor"></span></p>
        )
    }

    if (freestyle) {
        return (
            <Terminal />
        )
    }

    return sceneRender[scene]();
}

export default Game;