import * as React from 'react';
import BackgroundText, { LETTER_DURACTION_ANIMETED } from '../../../story/backgroundText/BackgroundText';
import backgroundLab from './background_lab.png'
import backgroundFather from './background_father.png'
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeScene } from '../../actions';
import { Scene } from '../../scene';

let parts = [
    {
        text: ` Isso, agora a centrifuga está ligando novamente.`,
        background: backgroundFather
    },
    {
        text: ` Agora vamos tentar ler alguns artigos no futuro e tentar descobrir
            alguma pista de como podemos começar a elaborar um plano para derrubar
            o grupo sabolha.`
    }
];

export default () => {    
    const [render, setRender] = React.useState<any>({});
    const dispatch = useDispatch();
    const [name]: any = useSelector<any>(state => ([
        state.firebase.auth.displayName
    ]));

    React.useEffect(() => {
        setTimeout(() => {
            if(parts.length < 1) {
                setTimeout(() => {
                    dispatch(changeScene(Scene.READ_IN_FUTURE))
                }, 500);
                return;
            }

            setRender(parts.shift());
        }, (LETTER_DURACTION_ANIMETED*render.text?.length || 1)+5000)
    }, [render, parts]);

    return (
        <>
            {render.text ? (
                <BackgroundText backgroundImage={render.background} backgroundStyle={render.backgroundStyle ? render.backgroundStyle : {}}>
                    {render.text.replace(/\$name/g, name)}
                </BackgroundText>
            ): null}
        </>
        
    )
};