import * as React from 'react';
import Terminal from "../terminal/Terminal";
import LoginScript from "./terminalScripts/LoginScript";
import Introduction from './stories/introduction/Introduction';
import VerifyProjectModulesScript from './terminalScripts/VerifyProjectModulesScript';
import Finish from './stories/finish/Finish';
import commands from './terminalCommands/commands';
import SecurityMode from './terminalScripts/SecurityMode';
import Cent92 from './stories/cent92/Cent92';
import BrowserTutorial from './terminalScripts/BrowserTutorial';
import { HomeworkOne } from './terminalScripts/HomeworkOne';
import ChallengeComplete from './ChallengeComplete';
import UniversityServersWWW from './stories/university-servers-www/UniversityServersWWW';
import VerifyAfterBroken from './terminalScripts/VerifyAfterBroken';
import ByeFather from './stories/bye-father/ByeFather';
import WelcomeScript from './terminalScripts/WelcomeScript';
import IntroductionScript from './terminalScripts/IntroductionScript';


export enum Scene {
    LOGIN = 'LOGIN',
    WELCOME = 'WELCOME',
    INTRODUCTION = 'INTRODUCTION',
    INTRODUCTION_HISTORY = 'INTRODUCTION_HISTORY',
    VERIFY_PROJECT_MODULES = 'VERIFY_PROJECT_MODULES',
    READ_IN_FUTURE_INTRODUCTION = 'READ_IN_FUTURE_INTRODUCTION',
    READ_IN_FUTURE = 'READ_IN_FUTURE',

    /* Old History */
    VERIFY_PROJECT_MODULES_AFTER_FIX_HARDWARE = 'VERIFY_PROJECT_MODULES_AFTER_FIX_HARDWARE',
    CENT_CONNECTED = 'CENT_CONNECTED',
    BROWSER_TUTORIAL = 'BROWSER_TUTORIAL',
    HOMEWORK_ONE = 'HOMEWORK_ONE',
    HOMEWORK_ONE_DONE = 'HOMEWORK_ONE_DONE',
    UNIVERSITY_SERVER_WWW = 'UNIVERSITY_SERVER_WWW',
    VERIFY_AFTER_BROKEN = 'VERIFY_AFTER_BROKEN',
    RETURN_TO_UNIVERTIY = 'RETURN_TO_UNIVERSTIY',
    DEFAULT_FACTORY_PASSWORD = 'DEFAULT_FACTORY_PASSWORD',
    FINISH = 'FINISH'
}

export const sceneRender = {
    [Scene.LOGIN]: () => (
        // Agora o script apenas redireciona para /
        <Terminal key="login-script" script={LoginScript} />
    ),

    [Scene.WELCOME]: () => (
        <Terminal key="welcome" script={WelcomeScript} />
    ),

    [Scene.INTRODUCTION]: () => (
        <Terminal key="introduction-script" script={IntroductionScript} />
    ),

    [Scene.INTRODUCTION_HISTORY]: () => (
        <Introduction />
    ),

    [Scene.VERIFY_PROJECT_MODULES]: () => (
        <Terminal
            key="verify_project_modules"
            script={VerifyProjectModulesScript}
            commands={commands} 
        />
    ),

    [Scene.READ_IN_FUTURE_INTRODUCTION]: () => (
        <Cent92 />
    ),

    [Scene.READ_IN_FUTURE]: () => (
        <Terminal
            key="read_in_future"
            commands={commands}
        />
    ),

    /**
     * OLD STAGE History
     */

    [Scene.VERIFY_PROJECT_MODULES_AFTER_FIX_HARDWARE]: () => (
        <Terminal
            key="verify_project_modules_after_fix_hardware"
            script={SecurityMode}
            commands={commands}
        />
    ),

    [Scene.CENT_CONNECTED]: () => (
        <Cent92 />
    ),

    [Scene.BROWSER_TUTORIAL]: () => (
        <Terminal
            key="browser_tutorial"
            script={BrowserTutorial}
            commands={commands}
        />
    ),

    [Scene.HOMEWORK_ONE]: () => (
        <Terminal
            key="homework_one"
            script={HomeworkOne}
            commands={commands}
        />
    ),

    [Scene.HOMEWORK_ONE_DONE]: () => (
        <ChallengeComplete nextScene={Scene.UNIVERSITY_SERVER_WWW} completedChallenge="Trabalho da faculdade - W3" />
    ),

    [Scene.UNIVERSITY_SERVER_WWW]: () => (
        <UniversityServersWWW />
    ),

    [Scene.VERIFY_AFTER_BROKEN]: () => (
        <Terminal
            key="verify_after_broken"
            script={VerifyAfterBroken}
            commands={commands}
        />
    ),

    [Scene.RETURN_TO_UNIVERTIY]: () => (
        <ByeFather />
    ),

    [Scene.DEFAULT_FACTORY_PASSWORD]: () => (
        <p>DEFAULT_FACTORY_PASSWORD</p>
    ),

    [Scene.FINISH]: () => (
        <Finish />
    )
};