export type EventType = {}

type SubscribedEvent = {
    event: string,
    callback: (event: EventType) => void
}

class Events {
    private subscribedEvents: Array<SubscribedEvent> = [];
    private static instance: Events;

    private constructor() {}

    static getInstance(): Events {
        if (Events.instance) return Events.instance;
        return new Events();
    }

    subscribe(event: string, callback: (event: EventType) => void) {
        this.subscribedEvents.push({event, callback})
    }

    dispatch(event: string, data: EventType) {
        this.subscribedEvents.forEach((subscribedEvent) => {
            if (subscribedEvent.event == event) {
                subscribedEvent.callback(data);
            }
        });
    }
}

export default Events.getInstance();