import Script from "../../terminal/Script";
import TerminalApi from "../../terminal/TerminalApi";
import settings from "../../shared/settings";
import { changeScene } from "../actions";
import { Scene } from "../scene";


export default class implements Script {
    constructor (private t: TerminalApi) {}

    async run() {
        await this.t.print(`Seja bem vindo ao modo historia do ${settings.project.name}.`, true, {
            color: 'green'
        });

        this.t.setTerminalHelp(`
            <h2>Antes de começar!</h2>

            <p>
                Antes de começar o modo historia, é importante resaltar que todo
                o seu progresso é salvo na sua conta, então você pode continuar
                do checkpoint que parou de qualquer lugar.
            </p>
            <br />
            <p>
                Lembre-se também, alguns recursos são baseados na vida real
                porém com algumas adptações para criar uma imersão na historia e
                garantir uma boa jogabilidade e experiencia.
            </p>

            <a data-command="continue" class="command">Entendido!</a>
        `);

        await this.t.continue();
        this.t.clear();
        this.t.setTerminalHelp("");

        await this.t.print('Recebendo e-mail...', true, {
            color: 'blue'
        });

        await this.t.print(`
            Meu nome é Antonio, achei seu contato navegando por um site chamado catfuture, 
            eu sei que você não me conhece mas eu percebi aqui que você tem algumas skills de 
            profissional de TI em ${(new Date()).getFullYear()}.
            
            Eu sei, vai ser muito estranho o que eu vou dizer a seguir, mas preciso que você 
            acredite. Eu estou falando do ano de 1994 e eu preciso de sua ajuda.
            
            Meu pai criou uma sessão remota e eu dei uma aprimorada para disponibilizar para você,
            do futuro.

            Basta clicar no link "Acessar sessão remota".

            Att, Antonio
            <strong>8 set 1994</strong>
        `, true);

        this.t.setTerminalHelp(`
            <a data-command="continue" class="command">Acessar sessão remota!</a>
        `);
        await this.t.continue("Para continuar, clique ao lado em acessar sessão remota");

        this.t.dispatch(changeScene(Scene.INTRODUCTION));
    }
}