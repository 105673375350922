import firebase from "firebase";

type Package = {
    description: string;
    owner: string;
}

export const list = async(): Promise<string[]> => {
    try {
        const user = await firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser?.uid)
            .get();
        
        return user.data()?.packages || [];
    } catch (err) {
        return [];
    }
};

export const install = async(packageToInstall: string) => {
    await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser?.uid)
        .set({
            packages: [...(await list()), packageToInstall]
        }, {merge: true});
};

export const remove = async(packageToRemove: string) => {
    await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser?.uid)
        .set({
            packages: (await list()).filter((packageItem) => packageItem !== packageToRemove)
        }, {merge: true});
};

export const search = async(term: string) => {
    const serachByDescription = await firebase
        .firestore()
        .collection("packages")
        .where('description', '>=', term)
        .where('description', '<=', term+'\uf8ff')
        .get();

    const searchByCommand = await firebase
        .firestore()
        .collection("packages")
        .doc(term)
        .get();

    return [
        ...serachByDescription.docs,
        searchByCommand || null
    ];
};

export const getPackage = async(command: string): Promise<null|Package> => {
    try {
        const pck = await firebase
            .firestore()
            .collection("packages")
            .doc(command)
            .get();
        
        if (!pck.exists) {
            return null;
        }

        return (pck.data() as Package);
    } catch(err) {
        console.error(err);
        return null;
    }
};