import React from 'react';
import './Input.css';

type Props = {
    onFinishInput: (text: string) => void,
    commandsHistory?: Array<string>,
    inputLabel?: string|null
}

const Input: React.FunctionComponent<Props> = ({
    onFinishInput,
    commandsHistory = [],
    inputLabel = "$"
}: Props) => {
    const userInput = React.useRef<HTMLInputElement>(null);
    const [historyIndex, setHistoryIndex] = React.useState(commandsHistory.length);
    React.useEffect(() => {
        if (!userInput?.current) return;

        userInput.current.focus();
        window.addEventListener('keypress', (event) => {
            userInput.current?.focus();
        });

        userInput.current.addEventListener('keypress', (event) => {
            if (event.key === 'Enter') {
                if (userInput?.current) onFinishInput(userInput.current.innerText);
            }
        });
    }, [userInput, onFinishInput]);

    React.useEffect(() => {
        setCaretToEnd(userInput.current);
    }, [userInput.current?.innerText]);

    const navigate = (event: any) => {
        if (!userInput.current) return;

        if (event.key == "ArrowUp" && historyIndex > 0) {
            userInput.current.innerText = commandsHistory[historyIndex-1];
            setHistoryIndex((prevValue) => prevValue-1);
        }

        if (event.key == "ArrowDown" && historyIndex < commandsHistory.length) {
            if (!commandsHistory[historyIndex+1]) return userInput.current.innerText = '';
            userInput.current.innerText = commandsHistory[historyIndex+1];
            setHistoryIndex((prevValue) => prevValue+1);
        }
    };

    return (
        <p>
            <span className="prepend">{inputLabel}</span>
            <span className="input" onKeyDown={(event) => navigate(event)} ref={userInput} contentEditable={true}></span>
            <span className="cursor"></span>
        </p>
    );
};

const setCaretToEnd = (element: any) => {
    let range;
    let selection;

    if(document.createRange) {
        range = document.createRange();
        range.selectNodeContents(element);
        range.collapse(false);
        selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
    }
}

export default Input;