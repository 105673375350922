import * as React from 'react';
import { WebSiteProps } from '../../../browser/Browser';

export const MosaicHomePage = "http://www.ncsa.uiuc.edu/SDG/Software/Mosaic/NCSAMosaicHome.html";

export default ({
    browserApi,
    url
}: WebSiteProps) => {

    React.useEffect(() => {
        browserApi.setTitle(url === MosaicHomePage ? 'NCSA Mosaic Homepage' : 'NCSA');
    }, [url]);
    
    if (url !== "http://www.ncsa.uiuc.edu/SDG/Software/Mosaic/NCSAMosaicHome.html") {
        return (
            <div>
            <img src={require('./ncsa-logo.gif')} />
            <br />
            <p>Erro: Não foi possível carregar este site</p>
            </div>
        );
    }

    return (
        <div className="page" dangerouslySetInnerHTML={{__html: `
            <img src="${require('./mosaic.gif')}" alt="NCSA Mosaic Image" width="383" height="179">
            <br><br>
            Welcome to NCSA Mosaic, an Internet information browser and <a href="#NADAAQUI">World Wide Web</a>
            client.  NCSA Mosaic was developed at the <a href="#NADAAQUI">National Center for
            Supercomputing Applications</a> at the <a href="#NADAAQUI">
            University of Illinois</a> in <a href="#NADAAQUI">
            Urbana-Champaign</a>.  NCSA Mosaic software is 
            <a href="#NADAAQUI">copyrighted </a>
            by The Board of Trustees of the University of Illinois (UI), and ownership 
            remains with the UI.
            <br>
            
            <h2><a href="#NADAAQUI">
            What is NCSA Mosaic?</a></h2>
            <p>
            <h2>NCSA Mosaic Platforms:</h2>
            
            NCSA Mosaic runs on three platforms .  The most recent version number 
            follows each hyperlink.
            <p>
            
            <ul>
            <li> <a href="#NADAAQUI">
            NCSA Mosaic for the X Window System</a> (v2.8a2)
            
            <li> <a href="#NADAAQUI">
            NCSA Mosaic for the Apple Macintosh</a> (v3.0b4)
            
            <li> <a href="#NADAAQUI">
            NCSA Mosaic for Microsoft Windows</a> (v2.1.1)
            </ul>
            
            <h2>World Wide Web Resources</h2>
            
            The following resources are available to help introduce you to
            cyberspace and keep track of its growth:
            <ul>
            <li> A <a href="#NADAAQUI">glossary</a> of World Wide Web 
            terms and acronyms
            
            <li> An  
            <a href="#NADAAQUI">
            INDEX</a> to Mosaic related documents
            
            <li><a href="#NADAAQUI">NCSA Mosaic Access Page</a> for
            persons with disabilities
            
            <li> Mosaic and WWW related <a href="#NADAAQUI">
            Tutorials</a>
            
            <li><a href="#NADAAQUI">Internet Resources Meta-Index</a> at NCSA
            
            <li>Suggested <a href="#NADAAQUI">Starting Points for Internet Exploration</a> 
            
            <li>Archives of the <a href="#NADAAQUI">What's New</a> with NCSA Mosaic and
                the Internet
            
            </ul>
            
            <h2>Special Notices</h2>
            
            <ul>
            
            <li><a href="#NADAAQUI">International World Wide Web Conference Committee - IW3C2</a>
            
            <li><a href="#NADAAQUI"> SGML on the Web</a> and the first
            <a href="#NADAAQUI">
            SGML Web Browser, Panorama</a>
            
            <li>NCSA's <a href="#NADAAQUI"> VRML</a> home page
            
            <li> NCSA Mosaic won many<a href="#NADAAQUI"> awards!
            
            </a>
            
            <li><a href="#NADAAQUI"> 
            Non-Commercial Use of NCSA Mosaic Source Code</a>
            
            <li>Commercial licensing of Mosaic technology with <a href="#NADAAQUI">Spyglass, 
            Inc.</a>
            
            </ul>
            
            <h2>Feedback</h2>
            
            If you have questions or comments concerning NCSA Mosaic, send
            e-mail to one of the following addresses: <p>
            
            <ul>
            <li> <b><a href="#NADAAQUI">mosaic-help@ncsa.uiuc.edu</a></b>
            - General information relevant to all Mosaic platforms. 
            <li> <b><a href="#NADAAQUI">mosaic-w-help@ncsa.uiuc.edu</a></b> 
            - Mosaic for Microsoft Windows help
            <li> <b><a href="#NADAAQUI">mosaic-m-help@ncsa.uiuc.edu</a></b> 
            - Mosaic for Macintosh help
            <li> <b><a href="#NADAAQUI">mosaic-x-help@ncsa.uiuc.edu</a></b> 
            - Mosaic for the X Windows System
            </ul>
            
            <hr>
            <address>
            <a href="#NADAAQUI">mosaic-help@ncsa.uiuc.edu</a><br>
            <a href="#NADAAQUI">
            National Center for Supercomputing Applications</a><br>
            <a href="#NADAAQUI">
            The University of Illinois at Urbana/Champaign</a>
            </address>

        `}} />
    )
};