import * as React from 'react';
import BackgroundText, { LETTER_DURACTION_ANIMETED } from '../../../story/backgroundText/BackgroundText';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeScene } from '../../actions';
import { Scene } from '../../scene';

let parts = [
    {
        text: ` Depois que terminei o trabalho da faculdade, fui direto para a faculdade.`,
        background: require('./classrom.jpg'),
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Professor: Sobre o trabalho que eu passei na semana passada, irei passar uma lista para vocês preencherem o nome de usuários de vocês e eu irei enviar para o departamento de TI da faculdade para colocarem na homepage da fatec comunidade.`,
        background: require('./teacher.png'),
        backgroundStyle: {
            backgroundSize: "50%",
            backgroundPositionY: "bottom"
        }
    },
    {
        text: ` Acabou a aula, hora de ir para casa.`,
        background: require('./classrom.jpg'),
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Pai: Você chegou em casa. Tenho uma noticia pessima.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` $name: Qual noticia?`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Pai: Veja esta carta que chegou hoje.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` 
            Prezado,

            Comunicamos que sua verba de pesquisa será desativada, devido os baixos resultados que o seu projeto vem demosnstrado.
            Informamos também, que os equipamentos e laboratorios sediados pela AEB, serão desativados até segunda feira, 13 de novembro de 1994.

            Grato, AEB.
            Agencia Espacial Brasileira.
        
            `,
        background: require('./carta.png'),
        backgroundStyle: {
            backgroundSize: "50%",
            backgroundPositionY: "bottom"
        }
    },
    {
        text: ` $name: Não tem como recorrer?`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Pai: Tem, mas precisamos mostrar resultados, e a gente não tem.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` $name: Temos uma semana, vamos dar o melhor de nós!`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` No dia seguinte no laboratorio.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Pai: Não aguento mais isso, é impossível acelerar qualquer coisa na velocidade da luz para manipular o espaço-tempo.
                Sou um tolo por acreditar nisso.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Em um momento de raiva, meu pai joga sua prancheta sobre a centrifuga atomica e quebra a tampa de vidro.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Pai: Não aguento mais e olha só que eu fiz, vou lá fora tomar um ar.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` $name: Preciso concertar isso para quando meu pai voltar.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` Então você concerta, mas para segurar a tampa de vidro, eu fiz algumas gambiarras para ganhar tempo.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
    {
        text: ` $name: Agora preciso verificar o hardware no project-manager.`,
        background: '',
        backgroundStyle: {
            backgroundSize: "cover"
        }
    },
];

export default () => {    
    const [render, setRender] = React.useState<any>({});
    const dispatch = useDispatch();
    const [name]: any = useSelector<any>(state => ([
        state.firebase.auth.displayName
    ]));

    React.useEffect(() => {
        setTimeout(() => {
            if(parts.length < 1) {
                setTimeout(() => {
                    dispatch(changeScene(Scene.VERIFY_AFTER_BROKEN))
                }, 500);
                return;
            }

            setRender(parts.shift());
        }, (LETTER_DURACTION_ANIMETED*render.text?.length || 1)+5000)
    }, [render, parts]);

    return (
        <>
            {render.text ? (
                <BackgroundText backgroundImage={render.background} backgroundStyle={render.backgroundStyle ? render.backgroundStyle : {}}>
                    {render.text.replace(/\$name/g, name)}
                </BackgroundText>
            ): null}
        </>
        
    )
};