import TerminalApi from "../../terminal/TerminalApi";
import createFileFromTemplate from "../../shared/createFileFromTemplate";

export default class ManufacturerPassword {
    constructor(private t: TerminalApi) {}

    async run() {
        this.t.print('Starting system... <span class="cursor"></span>', true, {
            color: 'green'
        });
        await createFileFromTemplate(
            this.t.state().firebase.profile.username,
            [
                '/cent92/cent.config.ini',
                '/cent92/manual.txt'
            ]
        )

        this.t.clear();

        await this.t.executeCommand("project-manager hardware verify", true);
        
        return this.t.done();
    }
}