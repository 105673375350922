import Script from "../../terminal/Script";
import TerminalApi from "../../terminal/TerminalApi";

export default class implements Script {
    constructor(private t: TerminalApi) {}

    async run() {
        this.t.setTerminalHelp(`
            <h2>Você concertou?</h2>

            <p>
                Agora, usando o project-manager, você precisa verificar se o hardware
                ainda está funcionando depois de ter sido quebrado.
            </p>

            <a data-command="project-manager --help" class="command">project-manager</a>
        `)
    }
}