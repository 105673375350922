import React, { FunctionComponent } from 'react';
import './BackgroundText.css';

export const LETTER_DURACTION_ANIMETED = 50;

type Props = {
    backgroundImage: any
    children: string,
    backgroundStyle?: any
};

const BackgroundText: FunctionComponent<Props> = ({
    backgroundImage,
    backgroundStyle = {},
    children
}) => {
    const [animatedText, setAnimatedText] = React.useState<Array<string>>([""]);

    React.useEffect(() => {
        const writeLetter = () => {
            if (animatedText.length >= children.length) return;

            let letter = children.slice(
                animatedText.length,
                animatedText.length+1
            );

            if (letter === "¢" || letter === "\n") {
                letter = "<br />";
            }

            setAnimatedText(() => ([...animatedText, letter]))
        };

        setTimeout(() => writeLetter(), LETTER_DURACTION_ANIMETED);
    }, [animatedText]);
    
    React.useEffect(() => {
        setAnimatedText(() => ([""]));
    }, [children]);

    return (
        <div className="background" style={{...backgroundStyle, backgroundImage: `url(${backgroundImage})`}}>
            <p className="history" dangerouslySetInnerHTML={{__html: animatedText.join("") }}></p>
        </div>
    );
};

export default BackgroundText;