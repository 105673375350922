import firebase from 'firebase/app';

const createFileFromTemplate = async(userId: string, file: string, forceReplace = false) => {
    const userPath = firebase
        .storage()
        .ref(`/home/${userId}`);

    if (!forceReplace) {
        try {
            await userPath.child(file)
                .getDownloadURL();
            return;
        } catch(exception) {}
    }

    const templateFile = await firebase
        .storage()
        .ref(`/home/template/${file}`)
        .getDownloadURL();

    const fileContents = await (await fetch(templateFile)).text();
    return userPath.child(file).putString(fileContents);
};

export default async(userId: string, files: Array<string>, forceReplace = false) => {
    for(let file of files) {
        await createFileFromTemplate(userId, file, forceReplace);
    }

    return new Promise((resolve) => resolve());
}