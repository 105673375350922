import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import applyGameReducers from './../game/reducers';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import { firebaseReducer as firebase, getFirebase } from 'react-redux-firebase';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage
}

export default (initialState = {}) => {
    const persistedReducer = persistReducer(persistConfig, combineReducers({
        game: applyGameReducers,
        firebase: persistReducer(
            { key: 'firebaseState', storage, stateReconciler: hardSet, blacklist: ['profile'] },
            firebase
        )
    }));

    const middlewares = [
        thunk.withExtraArgument({getFirebase})
    ]

    const hasRedux = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();
    
    const enhancer = hasRedux ? (
        compose(
            applyMiddleware(...middlewares),
            hasRedux
        )
    ) : applyMiddleware(...middlewares);
    const store = createStore(
        persistedReducer,
        enhancer
    );

    const persist = persistStore(store);

    return {store, persist};
}
