import TerminalApi from "../../terminal/TerminalApi";
import * as ProjectManager from "../terminalCommands/modules/ProjectManager";
import firebase from "firebase";
import createFileFromTemplate from "../../shared/createFileFromTemplate";
import settings from "../../shared/settings";
import { createVerifyProjectModuleChallenge } from "../challenges/VerifyProjectModule";

export default class VerifyProjectModules {
    constructor(private t: TerminalApi) {}

    async run() {
        await this.t.print('Starting System...', true, {
            color: 'green'
        });

        await createVerifyProjectModuleChallenge(this.t);

        this.t.clear();
        
        this.t.setTerminalHelp(`
            <h2>Me ajude a ligar a centrifuga.</h2>
            
            <p>
                Preciso ligar a centrifuga, mas parece que ela está dando alguns problemas
                para ligar.
            </p>

            <p>
                Use o project-manager para manipular o projeto, deve-se ficar disponivel o hardware
                da centrifuga para ser possível ligar ela.
            </p>

            <br /><br />
            <a data-command='project-manager --help' class='command'>project-manager</a>
            <a data-close="true" class='command'>Entendi!</a>
        `);

        return this.t.done();
    }
}