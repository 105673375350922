import Script from "../../terminal/Script";
import TerminalApi from "../../terminal/TerminalApi";
import createFileFromTemplate from "../../shared/createFileFromTemplate";
import websites from "../websites/websites";
import { changeScene } from "../actions";
import { Scene } from "../scene";

export default class implements Script {
    constructor (private t: TerminalApi) {}

    async run() {
        this.t.print('Starting system... <span class="cursor" />', true, {
            blink: true,
            color: 'green'
        });

        await createFileFromTemplate(
            this.t.state().firebase.profile.username,
            [
                '/project-manager/connect_to_hardware.c',
                '/project-manager/project_manager.c',
                '/cent92/cent.config.ini',
                '/cent92/manual.txt'
            ]
        );

        this.t.clear();

        this.t.setTerminalHelp(`
            <h2>Bem vindo ${this.t.state().firebase.auth.displayName || ''}</h2><br />

            <p>
                Meu nome é Antonio, e foi meu pai quem criou essa sessão remota,
                aqui você consegue acessar diretamente um computador que tem aqui no laboratorio.
            </p>
            <br />
            <p>
                Calma, irei contar toda a historia, mas antes, vou te ensinar a usar essa sessão remota
                e tenha um pouco de paciencia, andei lendo o que tem de processador na sua epoca, tenha em mente
                que aqui em 1994, a gente não tem processadores tão rápidos como os da sua epoca.
            </p>
            <br /><br />
            <a data-command="continue" class="command">Continuar</a>
        `);

        await this.t.continue("Leia as instruções ao lado para continuar.");
        this.t.clear();

        this.t.setTerminalHelp(`
            <h2>Este é o terminal.</h2> <br />
            <p>
                Esse terminal foi meu pai que construiu, ele não tem todos os recursos
                que você encontra no unix/windows por exemplo, mas foi totalmente baseado
                no unix.
            </p>
            <br />
            <p>
                É uma maquina configurada aqui, então a qualquer momento que você acessar
                a sessão remota, você vai conseguir acessar o mesmo terminal.
            </p>
            <br /><br />
            <a data-command="continue" class="command">Continuar</a>
        `);

        await this.t.continue("Leia as instruções ao lado para continuar.");

        await this.t.print('$ pwd', true, {
            color: '#ccc'
        });
        await this.t.executeCommand('pwd');
        await this.t.print('$ ls', true, {
            color: '#ccc'
        });
        await this.t.executeCommand('ls');
        this.t.setTerminalHelp(`
            <h2>Sua pasta home</h2> <br />

            <p>
                Sua pasta home é onde fica todos os seus arquivos e
                você não tera acesso root.
            </p>
            <br />
            <p>
                Sempre que você acessar a sessão remota, você usará o mesmo
                computador, então, sempre encontrara os seus arquivos aqui.
            </p>

            <a data-command="continue" class="command">Continuar</a>
        `);
        await this.t.continue("Leia as instruções ao lado para continuar.");

        await this.t.print('$ cd project-manager', true, {
            color: '#ccc'
        });
        await this.t.executeCommand('cd project-manager');
        await this.t.print('$ ls', true, {
            color: '#ccc'
        });
        await this.t.executeCommand('ls');

        this.t.setTerminalHelp(`
            <h2>Arquivos sobre demanda!</h2> <br />
            <p>
                Eu também consigo criar arquivos na sua pasta home daqui.
                Conforme você for me ajudando, irei criando alguns arquivos na
                sua pasta home.
            </p>
            <br />
            <p>
                Por exemplo, já coloquei os arquivos do project-manager, a primeira
                ajuda que eu preicso de você.
            </p>
            <br /><br />
            <a data-command="continue" class="command">Continuar</a>
        `);
        await this.t.continue();
        this.t.executeCommand('cd ..');
        this.t.clear();

        this.t.setTerminalHelp(`
            <h2>Além do terminal</h2> <br />
            <p>
                Existe outros elementos além do terminal, como por exemplo
                o browser, para poder navegar pela WWW, mais conhecido
                como internet na sua decada.
            </p>
            <br />
            <br />
            <br /><br />
            <a data-command="continue" class="command">Continuar</a>
        `);
        await this.t.continue();
        this.t.clear();

        this.t.setTerminalHelp(`
            <h2>Testando o Browser!</h2> <br />
            <p>
                Como eu disse anteriormente, também tem o browser!<br />
                Vou te mostrar como funciona!
            </p>
            </ul>
            <br /><br />
            <a data-command="continue" class="command">Abrir browser!</a>
        `);

        await this.t.continue();
        await this.browserTutorial();
        this.t.clear();

        this.t.setTerminalHelp(`
            <h2>Rede em 1994!</h2> <br />
            <p>
                Lembre-se que o computador dessa sessão remota está
                ligado em uma rede de 1994 com algumas limitações
                de acesso também.
            </p>
            </ul>
            <br /><br />
            <a data-command="continue" class="command">Continuar!</a>
        `);

        await this.t.continue();
        this.t.clear();


        this.t.setTerminalHelp(`
            <h2>Você não é o unico.</h2> <br />
            <p>
                Eu procurei ajuda de outras pessoas também, então você não é o unico.
                Infelizmente, existe também alguns harckers atacando o projeto e outros
                fatores que vou te explicar mais para frente, mas só queria deixar claro
                que sua ajuda será muito importante para salvar o futuro!
            </p>
            </ul>
            <br /><br />
            <a data-command="continue" class="command">Continuar</a>
        `);
        await this.t.continue();
        this.t.clear();

        this.t.setTerminalHelp(`
            <h2>Teste você mesmo.</h2> <br />
            
            <p>
                Vá em frente e tente digitar um comando antes de eu começar
                a contar toda a historia.
            </p>

            <br /><br />
            Algumas sugestões:
            <a data-command="ls" class="command">ls</a>
            <a data-command="pwd" class="command">pwd</a>
            <a data-command="clear" class="command">clear</a>
        `);
        await this.t.executeCommand(await this.t.input());

        this.t.setTerminalHelp(`
            <h2>Hora da historia</h2> <br />
            <p>
                Bom, já introduzi você a sessão remota.
                Eu sei que tudo é tão confuso, então vou contar a historia
                de como é possível você está usando essa sessão remota
                e eu aqui em 1994.
            </p>

            <a data-command="continue" class="command">Continuar</a>
        `);

        await this.t.continue();

        this.t.dispatch(changeScene(Scene.INTRODUCTION_HISTORY));
    }

    async browserTutorial() {
        await this.t.openBrowser(websites, 'bemvindo');
        this.t.setTerminalHelp(`
            <h2>Abrir o browser do terminal</h2><br />
            <p>Para abrir o browser a partir do terminal, 
               basta digitar <code>browser</code>.</p>
            <br /><br />
            Vá em frente, e abra o browser.
        `)

        this.t.clear();

        let command;
        do {
            command = await this.t.input();

            if (!command.match(/browser/g)) {
                this.t.print('Para abrir o browser, o comando precisa ser browser');
            }
        } while(!command.match(/browser/g));
        
        await this.t.openBrowser(websites, 'bemvindo/tutorial/final.html');
    }
}