import TerminalApi from "../../terminal/TerminalApi";
import extractArguments from "../../terminal/commands/extractArguments";
import firebase from "firebase";

let helper = async(t: TerminalApi) => {
    t.print(`gamemode: use para editar scene.
        - gamemode list
        - gamemode edit <id>
        - gamemode create <name>
    `);
};

let list = async(t: TerminalApi) => {
    let gamemodes;
    try {
        gamemodes = await firebase
            .firestore()
            .collection("gamemodes")
            .where('owner', "==", t.getUsername())
            .get();
    } catch (err) {
        console.error(err);
        return t.print('Ocorreu um erro para obter o gamemode.', false, {
            color: 'red'
        });
    }

    if (gamemodes.empty) {
        return t.print("Você não tem nenhum gamemode.");
    }

    gamemodes.forEach((gamemode) => {
        t.print(`#${gamemode.id} - ${gamemode.data().name || "Sem título"}`)
    });
};

let create = async(t: TerminalApi, name: string) => {
    if (!name) {
        t.print("gamemode create <name>");
        return;
    }

    try {
        const newGamemode = await firebase
            .firestore()
            .collection('gamemodes')
            .add({
                name,
                owner: t.getUsername()
            });
        
        t.print(`Gamemode criado com id ${newGamemode.id}`)
    } catch(err) {
        t.print('Ocorreu um erro para criar o gamemode.', false, {
            color: 'red'
        });
    }
};

let edit = async(t: TerminalApi, id: string) => {
    if (!id) {
        t.print("gamemode edit <id>")
        return;
    }


    const gamemode = await firebase
        .firestore()
        .collection('gamemodes')
        .doc(id)
        .get();

    if (!gamemode.exists) {
        console.log(`Gamemode id ${id} não existe.`);
        return;
    }
}

export default {
    gamemode: async(t: TerminalApi, $0: string) => {
        const command = extractArguments($0, -1);
        const argument = command.arguments[0];

        if (argument === "list") {
            return list(t);
        }

        if (argument === "create") {
            return create(t, command.arguments[1]);
        }

        if (argument === "edit") {
            return edit(t, command.arguments[1]);
        }

        return helper(t);
    }
};