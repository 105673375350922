import Browser from "./Browser";

export class BrowserApi {
    constructor(private browser: Browser) {}

    setTitle(title: string) {
        this.browser.setPageTitle(title);
    }

    setBrowserHelp(browserHelp: string) {
        this.browser.setBrowserHelp(browserHelp);
    }

    closeBrowser() {
        this.browser.props.close();
    }
}