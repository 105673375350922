export default {
    project: {
        name: 'catfuture',
        debug: false
    },
    firebase: {
        apiKey: "AIzaSyDMn9NPps_4BPgOUZtprg6tibuBg-etuQw",
        authDomain: "catfuture-45abe.firebaseapp.com",
        databaseURL: "https://catfuture-45abe.firebaseio.com",
        projectId: "catfuture-45abe",
        storageBucket: "catfuture-45abe.appspot.com",
        messagingSenderId: "1089023656791",
        appId: "1:1089023656791:web:1f3cacc7ebe5e70dc63412",
        measurementId: "G-HR8TCPWB5S"
    }
}
