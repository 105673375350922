import firebase from "firebase"
import TerminalApi from "../../terminal/TerminalApi"
import { Challenge } from "../../terminal/Terminal";

export const HomeworkOneGoals = {
    INSTRUCTION: 'Ler as instruções do desafio.',
    CREATE_HTML: 'Criar arquivo home.html',
    SEND_FTP: 'Enviar arquivo para FTP.'
}

const challengeInitialState = {
    title: 'Trabalho da faculdade - W3',
    goals: {
        doing: HomeworkOneGoals.INSTRUCTION,
        todo: [
            HomeworkOneGoals.CREATE_HTML,
            HomeworkOneGoals.SEND_FTP
        ],
        completed: []
    }
};

export class HomeworkOneChallege {
    constructor(private t: TerminalApi, private challege: any, private helpCallback: () => void) {
        this.setTerminalChallage();
    }

    getCurrentGoal() {
        return this.challege.goals.doing;
    }

    completeGoal(goal: string) {
        if (this.getCurrentGoal() != goal) return;
        this.challege.goals.completed.push(goal);
        this.challege.goals.doing = this.challege.goals.todo.shift() || '';

        firebase
            .firestore()
            .collection('users')
            .doc(`${firebase.auth().currentUser?.uid}/challenges/homeworkone`)
            .update(this.challege);

        this.setTerminalChallage();
    }

    setTerminalChallage() {
        this.t.setChallenge({
            ...this.challege,
            goalsDetails: true,
            helpCallback: this.helpCallback
        });
    }
}

export const createChallage = async(t: TerminalApi, helpCallback: () => void) => {
    const ref = await firebase
        .firestore()
        .collection('users')
        .doc(`${firebase.auth().currentUser?.uid}/challenges/homeworkone`);

    const currentProgress = await ref.get();

    if (currentProgress.exists) {
        t.setChallenge((currentProgress.data() as Challenge))
        return new HomeworkOneChallege(t, ({
            ...currentProgress.data()
        } as Challenge), helpCallback);
    }

    await ref.set(challengeInitialState);
    return new HomeworkOneChallege(t, {
        ...challengeInitialState
    }, helpCallback);
};

const challageHelp = () => new Promise(resolve => resolve());