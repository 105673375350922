import TerminalApi from "../../../terminal/TerminalApi";
import { fixHardware, changeScene } from "../../actions";
import { Scene } from "../../scene";
import firebase from "firebase";
import events from "../../../core/events";

export const HARDWARE_CONNECT_FIXED_EVENT = 'hardware_connect_fiexed_event';
export const CENT92_SETTINGS_AJUSTED = 'cent92_settings_ajusted'


const compileConnectHardware = async(t: TerminalApi) => {
    const readFile = await firebase
        .storage()
        .ref(`/home/${t.state().firebase.profile.username}/project-manager/connect_to_hardware.c`)
        .getDownloadURL();

    let fileConentsRaw = await (await fetch(readFile)).text();
    let fileConverted = fileConentsRaw
        .replace(/#.*/g, '')
        .replace(/\n/g, '')
        .replace(/(bool|void|int|string)/g, 'function')

    let result = "";
    console.log(`const project_manager_register_hardware = (s, h) => (\`\${s}\${h}\`);
    ${fileConverted}
    connect_hardware("S", "H");`);
    result = eval(`
        const project_manager_register_hardware = (s, h) => (\`\${s}\${h}\`);
        ${fileConverted}
        connect_hardware("S", "H");
    `);

    

    return result == "SH";
}

export const help = (t: TerminalApi) => {
    t.print(`
        <span style="color: green;">[Project Manager]</span> 
        Criado por Fransisco Golmar dos Santos
        Aprimorado por Federico Antonio dos Santos. 
        
        Comandos que você pode utilizar: 
        
        <span style="color: blue">project-manager hardware --help</span> - Manipulador do hardware
        <span style="color: blue">project-manager credits</span> - Ver créditos do ProjectManager
    `);
};


export const hardwareHelp = (t: TerminalApi) => {
    t.print(`
        Comandos que você pode utilizar para manipular hardware: 
        
        <span style="color: blue">project-manager hardware details</span> - Veja o detalhes dos hardwares do projeto
        <span style="color: blue">project-manager hardware verify</span> - Verificar conexões com hardware
    `);
};

export const hardwareDetails = async (t: TerminalApi) => {
    t.print(`
        <strong>Centrifuga Atomica</strong>
        Responsável por acelerar a materia atomicamente na velocidade da luz.


        <strong>Cabos de fibras</strong>
        Cabos de fibra fazem a comunicação entre os hardwares.


        <strong>Controlador</strong>
        Computador com ProjectModule instalado para gerenciar os harwares.


        Outros 17 componentes de hardware conectado sem descrição.
    `, false);
};

export const hardwareScan = async (t: TerminalApi) => {
    await t.print('Escaneando hardware...', true);
    await t.delay(1000);

    let result = false;
    try {
        result = await compileConnectHardware(t)
    } catch (ex) {
        t.print(`
            Erro ao compilar código.
            <span style="color: red">Erro:</span> ${ex.message}
            <span style="color: red">In file:</span> /home/${t.state().firebase.profile.username}/project-manager/connect_to_hardware.c
        `);

        t.setTerminalHelp(`
            <h2>Erro no código-fonte</h2>
            <br />
            Parece que ocorreu um erro no código-fonte. <br />
            Você pode navegar pelas pastas e até mesmo editar um arquivo usando o comando
            <code>edit [arquivo]</code>
            <br /><br />
            Você consegue resolver este problema? <br /><br />

            <a data-close="true" class="command">Entendido.</a>
        `);
        return t.done();
    }

    events.dispatch(HARDWARE_CONNECT_FIXED_EVENT, {});

    const configFile = await firebase
        .storage()
        .ref(`${t.getUserHome()}/cent92/cent.config.ini`)
        .getDownloadURL();
    
    const configContents = await (await fetch(configFile)).text();
    const securityMode = (configContents.match(/security_mode=(true|false)/) || ["_", "false"])[1];

    if (securityMode !== "false") {
        t.print(`
            <span style="color: red">Erro na Centrífuga Atomica</span>
            <span style="color: red">Error</span>: Desative o modo de segurança para utilizar o hardware nesta velocidade.
        `);

        return t.done();
    }

    await t.print(`Hardware conectado com sucesso.`, true, {
        color: 'green'
    });
    
    events.dispatch(CENT92_SETTINGS_AJUSTED, {});

    return t.done();
    
}

export const exceuteCommand = async (command: string, t: TerminalApi) => {
    switch(true) {
        case /^--help/g.test(command):
            return help(t);

        case /^hardware/g.test(command):
            switch(command.replace('hardware ', '')) {
                case 'details':
                    return hardwareDetails(t);
                case 'verify':
                    return hardwareScan(t);

                case '--help':
                default:
                    return hardwareHelp(t);
            }

        case /^credits/g.test(command):
            t.print(`Criado por ${t.state().firebase.auth.name} ¢
                Uso exclusivo para projeto de viagem no tempo.`);
            return;

        default:
            t.print("project-manager: argumentos inválidos.");
            t.print("tente digitar 'project-manager --help'")
            return;
    }
};