import { Terminal, PrintOptions, Challenge } from "./Terminal";
import { LINE_ANIMATED_LETTER_DURACTION } from "./Line";
import settings from "../shared/settings";
import { WebSiteProps } from "../browser/Browser";
import { FunctionComponent, ComponentElement } from "react";

export default class TerminalApi {
    constructor(private terminal: Terminal) {}

    async print(message: string, animated = false, options: PrintOptions = {}) {
        this.terminal.addLine({message, animated, options});
        if (animated && !settings.project.debug) await this.delay((message.length*LINE_ANIMATED_LETTER_DURACTION)+(LINE_ANIMATED_LETTER_DURACTION*3));
    }

    input(inputHelp: string|null = null, inputLabel?: string): Promise<string> {
        return this.terminal.requestInput(inputHelp, inputLabel);
    }

    async continue(warningAlert: string|null = null) {
        let input;
        do {
            input = await this.input();

            if (input !== "continue" && warningAlert) {
                this.print(warningAlert);
            }
        } while(input !== "continue")
    }

    delay(duraction: number) {
        return new Promise(resolve => setTimeout(resolve, duraction));
    }

    done() {
        return this.delay(0);
    }

    dispatch(action: any) {
        this.terminal.dispatch(action);
    }

    state() {
        return this.terminal.getStoreState();
    }

    clear() {
        this.terminal.clear();
    }

    editFile(fileContent: string, file: string) {
        return this.terminal.editFile(fileContent, file);
    }

    setTerminalHelp(terminalHelp: string|null = null) {
        this.terminal.setTerminalHelp(terminalHelp);
    }

    setFileHelp(fileHelp: string|null = null) {
        this.terminal.setFileHelp(fileHelp);
    }

    getScript() {
        return this.terminal.getScript();
    }

    getCurrentPath(): string {
        return this.terminal.getCurrentPath();
    }

    setCurrentPath(path: string) {
        return this.terminal.setCurrentPath(path);
    }

    async executeCommand(command: string, withPrint: boolean = false, keepHistory: boolean = false) {
        if (withPrint) {
            await this.print(`$ ${command}`, true, {
                color: "#ccc"
            });
        }
        
        return this.terminal.executeCommand(command, keepHistory);
    }

    getUserHome() {
        return `/home/${this.getUsername()}/`
    }

    getUserHomeDisplay() {
        return this.getUserHome();
    }

    getUsername() {
        return this.state().firebase.profile.username;
    }

    openBrowser(websites: {[key: string]: React.ComponentType<WebSiteProps>}, homepage?: string) {
        return this.terminal.setBrowser({websites, homepage});
    }

    setChallenge(challege: Challenge|null) {
        this.terminal.setChallenge(challege);
    }

    setOverlayComponent(component: FunctionComponent) {
        this.terminal.setOverlayComponent(component);
    }
}