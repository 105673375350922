import * as React from 'react';
import { Scene } from './scene';
import './ChallengeComplete.css';
import { useDispatch } from 'react-redux';
import { changeScene } from './actions';

type Props = {
    nextScene: Scene,
    completedChallenge: string
}



const ChallengeComplete: React.FunctionComponent<Props> = ({
    nextScene,
    completedChallenge
}) => {
    const dispatch = useDispatch();

    return (
        <div className="complete-page">
            <div className="done">
                <img src={require('./challenge_complete.png')} />
                <h1>Bom trabalho!</h1>
                <p>Você concluiu o desafio {completedChallenge}!</p>
                <a onClick={() => {
                    dispatch(changeScene(nextScene));
                }}>Continuar!</a>
            </div>

        {/*
            No futuro aqui vai ter as estáticas do desafio concluido
            como por exemplo, comandos executados, porcetagem e entre outros.

            <div className="statics">
                <h2>Detalhes</h2>
                
                <p>Completou 100% do desafio.</p>
            </div>

        */}
        </div>
    );
};

export default ChallengeComplete;