import { string } from "prop-types";

export type Options = {
    name: string,
    value: string|null
}

export type CommandSchema = {
    command: string,
    options: Array<Options>,
    arguments: Array<string>
}

export class InvalidCommand extends Error {}

export default (input: string, argsCount: number): CommandSchema => {
    const [fullCommand, command, args] = input.match(/^([\w-_\/\.\\]*)(?: | )(.*)/) || [null, null, null];

    if (!fullCommand || !command) {
        return {
            command: input,
            options: [],
            arguments: []
        }
    }

    const commandSchema: CommandSchema = {
        command,
        options: [],
        arguments: []
    }

    if (!args) return commandSchema;

    let optionsArray = args.split(' ');

    if (argsCount == -1) {
        optionsArray.slice().reverse().forEach((option) => {
            if (!option.startsWith('-')) {
                commandSchema.arguments.push(option);
            }
        })
    } else {
        Array.from(Array(argsCount).keys()).forEach((i) => {
            if (!optionsArray.slice().reverse()[i].startsWith('-')) {
                commandSchema.arguments.push(optionsArray.slice().reverse()[i]);
            }
        });
    }
    commandSchema.arguments.reverse();

    let optionsWithoutArgs = optionsArray.join(' ');
    if (!optionsWithoutArgs) return commandSchema;
    const options = optionsWithoutArgs?.matchAll(/((?:--|-)[\w]*)(?:[ =]{1})?(?:")?([\w=]*)(?:")?/g);

    for(const option of (options as any)) {
        let [_, key, value] = option;
        if ((key as string).startsWith('--')) {
            commandSchema.options.push({
                name: key.replace(/^--/, ''),
                value
            });
        } else {
            key = key.replace(/^-/, '');
            (key as string).split('').forEach((letter) => {
                commandSchema.options.push({
                    name: letter,
                    value: null
                })
            })
        }
    }

    return commandSchema;

}