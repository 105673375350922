import * as React from 'react';
import { WebSiteProps, NotFoundWebSite } from '../../../browser/Browser';
import { MosaicHomePage } from '../mosaic-home/MosaicHome';
import { useSelector } from 'react-redux';
import { Scene } from '../../scene';

const IntroductionHome: React.FunctionComponent<WebSiteProps> = ({
    browserApi,
    url
}) => {
    const scene = useSelector<any>(state => state.firebase.profile.scene);

    React.useEffect(() => {
        if (scene == Scene.INTRODUCTION) {
            browserApi.setBrowserHelp(`
                <h2>Esse é o Browser!</h2>

                <p>Este é o browser, baseado no navegador Mosaic, lançado em 1993. Aqui você pode navegar pela WWW.</p>
                <br /><br />
                <a data-website="http://bemvindo/possivelfechar.html" class="action">Continuar</a>
            `);
        }
    }, []);

    React.useEffect(() => {
        if (scene != Scene.INTRODUCTION) return;

        if (url.match(/possivelfechar.html/)) {
            browserApi.setBrowserHelp(`
                <h2>Feche a qualquer momento</h2>

                <p>Aperte no X vermelho alí para fechar e voltar para o terminal,
                    para abrir novamente o browser no terminal, basta digitar <code>browser</code>
                </p>
                <br /><br />
                <p>
                    Para você ver, vá em frente e feche o browser clicando no X vermelho.
                </p>
            `);
        }

        if (url.match(/tutorial\/final.html/)) {
            browserApi.setBrowserHelp(`
                <h2>Navegue!</h2>

                <p>Você pode digitar o endereço do site na barra de Document URL
                   para poder navegar entre os sites.</p>
                <br />
                <p>Lembre-se, alguns sites são criados por outras pessoas da sua decada, quando
                você acessar um site que foi criado por outro usuário, você recebera um alerta.<br />
                E mantenha a calma, logo mais você vai poder criar o seu proprio site também.</p>
                <br />
                <br /><br />
                <a data-website="http://bemvindo/finalizar-tutorial.html" class="action">Continuar</a>
            `);
        }

        if (url.match(/finalizar-tutorial.html/)) {
            browserApi.closeBrowser();
        }
    }, [url])

    if (scene !== Scene.INTRODUCTION) {
        return (
            <NotFoundWebSite url={url} browserApi={browserApi} />
        );
    }

    if (url.match(/tutorial\/final.html/)) {
        return (
            <>
                <h2>E esse é o Browser!</h2>
            </>
        )
    }

    return (
        <>
            <h2>Bem vindo a World Wide Web (W3)</h2>
            <p>Este é um tutorial guiado para te mostrar o browser antes de prosseguir.</p>
            <p>Siga as instruções ao lado.</p>
        </>
    );
}

export default IntroductionHome;