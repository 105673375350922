import * as React from 'react';
import MosaicHome from "./mosaic-home/MosaicHome";
import FatecHome from './fatec/FatecHome';
import { WebSiteProps } from '../../browser/Browser';
import IntroductionHome from './introduction-home/IntroductionHome';

export default {
    "http://ncsa.uiuc.edu": MosaicHome,
    "http://fatec.edu": FatecHome,
    "http://bemvindo": IntroductionHome
};