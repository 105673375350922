import React from 'react';
import Script from './Script';
import TerminalApi from './TerminalApi';
import Line from './Line';
import './Terminal.css';
import Input from './Input';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Browser, { WebSiteProps } from '../browser/Browser';
import firebase from 'firebase';
import events from '../core/events';
import commands from './commands';
import * as pck from './..//core/package/package';

export type PrintOptions = {
    color?: string,
    blink?: boolean,
    pre?: boolean
};

export type Challenge = {
    title: string,
    goals: {
        doing: string,
        todo: Array<string>,
        completed: Array<string>
    },
    helpCallback: () => Promise<any>,
    goalsDetails: boolean
}

type Line = {
    message: string,
    animated: boolean,
    options: PrintOptions
}

type Commands = {[key: string]: (t: TerminalApi, $0: string, callback?: () => Promise<any>) => void}

type Props = {
    script?: {new(...args: any[]): Script},
    dispatch: Dispatch,
    store?: any,
    commands?: Commands
}

type State = {
    output: Array<Line>,
    input: null|((text: string, callback?: () => Promise<any>) => void),
    //editFile: null|{complete: (text: string) => any, fileContents: string, originalContents: string}
    editFile: any,
    inputLabel: string|null,
    inputHelp: string|null,
    terminalHelp: string|null,
    fileHelp: string|null,
    commands: Commands,
    commandsHistory: Array<string>,
    script?: {new(...args: any[]): Script},
    currentPath: string,
    overlayComponent: React.FunctionComponent|null,
    browser: {websites: {[key: string]: React.ComponentType<WebSiteProps>}, homepage?: string, close: () => any}|null,
    challenge: Challenge|null
}

export class Terminal extends React.Component<Props, State> {
    state: State = {
        output: [],
        input: null,
        inputLabel: null,
        editFile: null,
        inputHelp: null,
        fileHelp: null,
        terminalHelp: null,
        overlayComponent: null,
        commands: {
            ...commands,
            ...this.props.commands || {}
        },
        commandsHistory: [],
        script: this.props.script,
        currentPath: `/home/${this.props.store.firebase.profile.username}/`,
        browser: null,
        challenge: null
    };

    editFileRef = React.createRef<any>();
    terminalHelp = React.createRef<HTMLDivElement>();
    terminalApi = new TerminalApi(this);

    addLine(line: Line) {
        this.setState((prevState) => ({...prevState, output: [...prevState.output, line]}));
    }

    clear() {
        this.setState((prevState) => ({...prevState, output: []}))
    }

    setTerminalHelp(terminalHelp: string|null = null) {
        this.setState((prevState) => {
            if (prevState.challenge && prevState.challenge.goalsDetails) {
                return {
                    ...prevState,
                    terminalHelp,
                    challenge: {
                        ...prevState.challenge,
                        goalsDetails: false
                    }
                }
            }

            return {
                ...prevState,
                terminalHelp
            }
        })
    }

    setFileHelp(fileHelp: string|null = null) {
        this.setState((prevState) => {
            if (prevState.challenge && prevState.challenge.goalsDetails) {
                return {
                    ...prevState,
                    fileHelp,
                    challenge: {
                        ...prevState.challenge,
                        goalsDetails: false
                    }
                }
            }

            return {
                ...prevState,
                fileHelp
            }
        })
    }

    setOverlayComponent(component: React.FunctionComponent|null = null) {
        this.setState((prevState => ({
            ...prevState,
            overlayComponent: component
        })));
    }

    setChallenge(challenge: Challenge|null) {
        this.setState(prevState => ({
            ...prevState,
            challenge
        }));
    }

    getStoreState() {
        return this.props.store;
    }

    getCurrentPath() {
        return this.state.currentPath;
    }

    setCurrentPath(path: string) {
        this.setState((prevState) => ({...prevState, currentPath: path}));
    }

    setBrowser(browser: {websites: {[key: string]: React.ComponentType<WebSiteProps>}, homepage?: string}|null) {
        const close = (resolve: () => any) => {
            this.setBrowser(null);
            resolve();
        };

        return new Promise((resolve) => {
            this.setState(prevState => ({
                ...prevState,
                browser: browser ? {
                    websites: browser.websites,
                    homepage: browser?.homepage,
                    close: () => close(resolve)
                } : null
            }));
        });
        
    }

    requestInput(inputHelp: string|null = null, inputLabel: string = "$"): Promise<any> {
        const completeInput = (resolve: (value?: Array<any>|string) => void, text: string, callback?: () => Promise<any>) => {
            this.setState((prevState) => ({...prevState, input: null, inputHelp: null, inputLabel: null}));
            this.addLine({
                message: `${inputLabel} ${text}`,
                animated: false,
                options: {
                    color: "#ccc"
                }
            });
            resolve(text);
        };

        return new Promise((resolve, reject) => {
            this.setState((prevState) => ({
                ...prevState,
                inputHelp,
                inputLabel,
                input: (text: string, callback?: () => Promise<any>) => {completeInput(resolve, text)}
            }));

        });
    }

    editFile(fileContents: string, file: string): Promise<string> {
        const completeEditFile = (resolve: (value?: string) => void, text: string) => {
            resolve(text);
            this.setState((prevState) => ({
                ...prevState,
                editFile: null
            }))

            events.dispatch('end_edit_file', {
                file,
                conent: fileContents
            });
        }

        events.dispatch('start_edit_file', {
            file,
            conent: fileContents
        });

        return new Promise((resolve, reject) => {
            this.setState((prevState) => ({
                ...prevState,
                input: null,
                editFile: {
                    complete: (text: string) => {completeEditFile(resolve, text)},
                    fileContents: fileContents,
                    originalContents: fileContents,
                    extension: file.substr(file.lastIndexOf('.')+1, file.length)
                }
            }));
        });
    }

    dispatch(action: Action) {
        this.props.dispatch(action);
    }

    async componentDidMount() {
        document.addEventListener('click', this.terminalHelpClick.bind(this));
        
        if (this.props.script) {
            const script = new this.props.script(this.terminalApi);
            await script.run();
        }

        await this.terminalLoop();
    }

    async componentWillUnmount() {
        document.removeEventListener('click', this.terminalHelpClick.bind(this));
    }

    terminalHelpClick(event: any) {
        if (event.target && event.target.dataset.command && this.state.input) {
            this.state.input(event.target.dataset.command);
        }

        if (event.target && event.target.dataset.close) {
            this.setTerminalHelp(null);
            this.setFileHelp(null);
        }
    }

    getScript() {
        return this.state.script;
    }

    componentWillUpdate(nextProps: Props, nextState: State) {
        /*if (
            (nextState.inputHelp || nextState.terminalHelp) &&
            this.terminalHelp.current &&
            nextState.input &&
            !this.state.input
        ) {
            const elements: Array<HTMLAnchorElement> = (this.terminalHelp.current.getElementsByClassName('command') as any);
            [...elements].forEach((command) => {
                const newCommand = command.cloneNode(true);
                command.parentNode?.replaceChild(newCommand, command);
                newCommand.addEventListener('click', (event) => {
                    if (command.dataset.command && nextState.input) {
                        nextState.input(command.dataset.command);
                    }

                    if (command.dataset.close) {
                        this.setTerminalHelp(null);
                    }

                    event.stopPropagation();
                }, false);
            });
        }*/
    }

    async componentDidUpdate() {
        if (this.state.editFile && this.editFileRef.current) {
            const element = this.editFileRef.current.getElementsByTagName('pre')[0];
            element.focus();

            element.addEventListener("blur", (event: any) => {
                event.currentTarget.focus();
            });

            const inputSaveFile = () => {
                this.setState((prevState) => ({
                    ...prevState,
                    input: (text) => {
                        console.log(text.toUpperCase());
                        if (text.toUpperCase().trim() === 'Y' || text.toUpperCase().trim() === 'S') {
                            this.state.editFile?.complete(
                                this.state.editFile.fileContents
                            );
                        } else if (text.toUpperCase().trim() === 'N') {
                            this.state.editFile?.complete(
                                this.state.editFile.originalContents
                            )
                        } else {
                            this.setState((prevState) => ({
                                ...prevState,
                                input: null
                            }));
                            return;
                        }

                        this.setState((prevState) => ({
                            ...prevState,
                            input: null,
                            editFile: null
                        }));
                    },
                    editFile: {
                        ...prevState.editFile,
                        fileContents: element.innerText
                    }
                }))
            };

            element.addEventListener('keydown', (event: any) => {
                if (event.key === 'Escape') {
                    inputSaveFile();
                }
            })
        }
    }

    async executeCommand(input: string, keepHistory: boolean = true) {
        const commandEvent = {
            input,
            command: "",
            commandFound: true,
            package: false
        }

        if (keepHistory) {
            this.setState((prevState) => ({
                ...prevState,
                commandsHistory: [...prevState.commandsHistory, input]
            }));
        }

        const onlyCommand = (input.match(/([\w-_\.\/]*) ?(.*)/) || ['_', 'unknown'])[1];
        commandEvent.command = onlyCommand;

        const command = this.state.commands[onlyCommand];
        if (!command) {
            const hasPackage = (await pck.list()).find(pckItem => pckItem === onlyCommand);
            if (hasPackage) {
                const packageContent = await (await fetch(await firebase.storage().ref(`/system/packages/${onlyCommand}/index.js`).getDownloadURL())).text();
                const packageExecute = eval(`${packageContent}`);
                packageExecute(this.terminalApi);
                commandEvent.package = true;
                firebase.analytics().logEvent('command_executed', commandEvent);
                return;
            }

            this.terminalApi.print(`${onlyCommand}: Comando inválido.`);
            commandEvent.commandFound = false;
            firebase.analytics().logEvent('command_executed', commandEvent);
            events.dispatch('command_executed', commandEvent);
            return;
        }
        
        firebase.analytics().logEvent('command_executed', commandEvent);
        events.dispatch('command_executed', commandEvent);
        await command(this.terminalApi, input);
    }

    async terminalLoop() {
        const requestCommand = async() => {
            const input = await this.requestInput();

            if (!input) {
                requestCommand();
                return;
            }

            await this.executeCommand(input);
            requestCommand();
            
        }

        requestCommand();
    }

    toggleGoalsDetails() {
        this.setState(prevState => {
            if (!prevState.challenge) return prevState;

            return {
                ...prevState,
                challenge: {
                    ...prevState.challenge,
                    goalsDetails: !prevState.challenge.goalsDetails
                }
            }
        });
    }

    async challengeHelp() {
        if (!this.state.challenge) return;
        await this.state.challenge.helpCallback();
    }

    render() {

        const ProgressBar = (props: any) => {
            if (!this.state.challenge) return (<></>);

            return (
                <>
                {this.state.challenge.goalsDetails ? (
                    <div className="window-goals terminal-help">
                        <h2>Objetivos</h2>
                        <br />
                        <p><strong>Desafio</strong>: {this.state.challenge.title}</p>
                        <p><strong>Objetivo atual</strong>:⏳ {this.state.challenge.goals.doing}</p>
                        <br />
                        <p>Proximos objetivo(s):</p>
                        <ul>
                            {this.state.challenge.goals.todo.map((todoItem, key) => (
                                <li key={key}>{todoItem}</li>
                            ))}
                        </ul>
                        <br />
                        <p>Objetivo(s) concluido(s):</p>
                        <ul className="completed-list">
                            {this.state.challenge.goals.completed.map((todoItem, key) => (
                                <li key={key}>{todoItem}</li>
                            ))}
                        </ul>
                        <br />
                        <a className="command" onClick={() => this.toggleGoalsDetails()}>Entendi! Ocultar desafios.</a>
                    </div>
                ) : null}

                    <div className="progress-bar">
                        <a className="help" href='#' onClick={() => this.challengeHelp()}>Ajuda</a>
                        <p><span>Desafio atual:</span> {this.state.challenge.title}</p>
                        <p><span>Objetivo atual:</span> {this.state.challenge.goals.doing}</p>
                        <a className="goals" href='#' onClick={() => this.toggleGoalsDetails()}>Objetivos »</a>
                    </div>
                </>
            )
        };

        if (this.state.overlayComponent) {
            return <this.state.overlayComponent />;
        }

        if (this.state.browser) {
            return (
                <Browser 
                    webSites={this.state.browser.websites}
                    homepage={this.state.browser.homepage}
                    close={this.state.browser.close}
                />
            )
        }

        if (this.state.input && this.state.editFile) {
            return (
                <div className="terminal-page">
                    <div className="terminal">
                        <Line animated={false} options={{}}>{`
                        Deseja salvar o arquivo? Digite<br/>
                        <span style="color: green">S</span> - Para salvar<br/>
                        <span style="color: green">N</span> - Para não salvar<br/>
                        <span style="color: green">Qualquer outra coisa</span> - Voltar a edição do arquivo.<br/>
                        `}</Line>
                        <Input onFinishInput={this.state.input.bind(this)} inputLabel="Salvar? " />
                    </div>
                    <div className="terminal-help">
                        <h2>Deseja salvar o arquivo?</h2>
                        <a data-command="S" className="command">Salvar</a>
                        <a data-command="N" className="command">Descartar</a>
                        <a data-command=" " className="command">Voltar a edição</a>
                    </div>
                </div>
            );
        }

        if (this.state.editFile) {
            return (
                <>
                <div className="edit-page">
                    <div className="editing">
                        <div className="bar">[ESC] Finalizar edição do arquivo.</div>
                        <div className="editFile" id="editFile" ref={this.editFileRef}>
                            <SyntaxHighlighter language={this.state.editFile.extension} style={docco} contentEditable={true}>
                                {this.state.editFile.fileContents}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                    {this.state.fileHelp ? (
                        <div ref={this.terminalHelp} className="terminal-help file-help" dangerouslySetInnerHTML={{__html: this.state.fileHelp || ""}} />
                    ): null}
                </div>
                <ProgressBar />
                </>
            )
        }

        return (
            <div className="page">
                <div className="terminal-page">
                    <div className="terminal">
                        {this.state.output.map((line, key) => (
                            <Line 
                                key={key}
                                animated={line.animated}
                                options={line.options}>{line.message}</Line>
                        ))}

                        {this.state.input ? <Input inputLabel={this.state.inputLabel} onFinishInput={this.state.input.bind(this)} commandsHistory={this.state.commandsHistory} /> : null}
                    </div>

                    {this.state.inputHelp || this.state.terminalHelp ? (
                        <div ref={this.terminalHelp} className="terminal-help" dangerouslySetInnerHTML={{__html: this.state.inputHelp || this.state.terminalHelp || ""}} />
                    ): null}

                    <ProgressBar />
                </div>
            </div>
        );
    }  
}

export default connect((state) => ({
    store: state
}))(Terminal);