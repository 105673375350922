import * as React from 'react';
import BackgroundText, { LETTER_DURACTION_ANIMETED } from '../../../story/backgroundText/BackgroundText';
import background from './background.png'
import backgroundLab from './background_lab.png'
import backgroundFather from './background_father.png'
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeScene } from '../../actions';
import { Scene } from '../../scene';

let parts = [
    {
        text: ` Tudo começa em 1989!`,
        background,
        backgroundStyle: {
            backgroundSize: 'cover'
        }
    },
    {
        text: ` Eu estava ajudando meu pai no projeto da vida dele
        enquanto os cientistas corriam para construir armas e míssil balístico,
        meu pai era crente na possibilidade que poderíamos viajar no tempo
        Ele vinha conduzindo um projeto em um grande laboratório.`
        ,
        background: backgroundLab,
    },
    {
        text: ` Meu pai morreu no final de 1993, cometeu suicidio e deixou seu legado, o seu projeto
            para mim.
        `,
        background: backgroundFather,
    },
    {
        text: ` Então, eu usei todas as anotações dele e evolui o projeto, mas em um acidente, a centrifuga
            atomica cedeu e o cabo de fibra foi sugado, então resultando na aceleração do cabo
            de fibra e o que deu origem ao o que eu chamo de 
            a rede do futuro.
        `
    },
    {
        text: ` Era tão obvio, o que podemos acelerar na velocidade da luz e mudar seu espaço-tempo? é claro,
            a luz.
            E foi assim que eu consigo trafegar bytes atráves do espaço-tempo.
        `
    },
    {
        text: ` A rede do futuro é capaz de transferir bytes entre o espaço-tempo, porém existe muitas limitações.
        `
    },
    {
        text: ` Descobri coisas terríveis sobre o futuro, e então comecei a elaborar um plano aonde eu juntaria
            pessoas do futuro para ajudar, já que os recursos que temos em 1994 era escacio, e devido a algumas
            rasuras nas anotações do meu pai, não foi possível reproduzir a centirfuga atomica, essa que está
            no laboratorio, é a unica.
        `
    },
    {
        text: ` Em junho de 1994, todo o laboratorio foi explodido, reduzindo todo o projeto a pó. E eu estava dentro.
            Foi então, quando eu morri.`
        //background: backgroundFather,
    },
    {
        text: ` Eu sou Antonio, uma inteligencia artifical baseada em Federico Antonio dos Santos.
            Fui criado para salvar o futuro de um perigo eminente, quando Federico descobriu que 
            sua (minha) morte seria inevitável.`
        //background: backgroundFather,
    },
    {
        text: ` Bom, resumindo, o mundo será tomado por uma revolta do que hoje você deve conhecer por "internet das coisas"
            guiado por um grupo de Hackers que nunca foi conhecido sua verdadeira identidade, apenas conhecido como
            Grupo Sabolha.`
        //background: backgroundFather,
    },
    {
        text: ` Nosso papel, é impedir que o grupo Sabolha conclua seu plano, em 2035 e passe a controlar o mundo.
            Para isso que preciso de sua ajuda, sou uma IA limitada progamada com muita limitações e em 1994.
            Bom, talvez "encadeados de IF" me definiria melhor.`
        //background: backgroundFather,
    },
    {
        text: ` Hoje eu estou em um laboratorio improvisado que Federico (eu de carne) criou e trouxe a centrifuga atomica,
            e se explodiu no laboratorio, dispistando minha existencia e a centrifuga.`
        //background: backgroundFather,
    },
    {
        text: ` Infelizmente, mesmo eu passando as coordenadas, nunca foi possível encontrar a centrifuga atomica a partir do futuro.`
        //background: backgroundFather,
    },
    {
        text: ` agora que expliquei tudo, que tal você começar a me ajudar?`
        //background: backgroundFather,
    }
];

export default () => {    
    const [render, setRender] = React.useState<any>({});
    const dispatch = useDispatch();
    const [name]: any = useSelector<any>(state => ([
        state.firebase.auth.displayName
    ]));

    React.useEffect(() => {
        setTimeout(() => {
            if(parts.length < 1) {
                setTimeout(() => {
                    dispatch(changeScene(Scene.VERIFY_PROJECT_MODULES))
                }, 2000);
                return;
            }

            setRender(parts.shift());
        }, (LETTER_DURACTION_ANIMETED*render.text?.length || 1)+5000)
    }, [render, parts]);

    return (
        <>
            {render.text ? (
                <BackgroundText backgroundImage={render.background} backgroundStyle={render.backgroundStyle ? render.backgroundStyle : {}}>
                    {render.text.replace(/\$name/g, name)}
                </BackgroundText>
            ): null}
        </>
        
    )
};