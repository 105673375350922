import TerminalApi from "../TerminalApi";
import firebase from 'firebase';
import { setLoading } from "../../game/actions";
import websites from "../../game/websites/websites";
import { MosaicHomePage } from "../../game/websites/mosaic-home/MosaicHome";
import extractArguments from "./extractArguments";
import { FilesystemFirebase } from "../../core/filesystem.firebase";
import { FileNotFoundError, IsDirectoryError } from "../../core/filesystem";

const fs = new FilesystemFirebase();

const ls = async (t: TerminalApi, $0: string) => {
    const command = extractArguments($0, 1);
    
    let path = t.getCurrentPath();
    if (command.arguments.length == 1) {
        path = command.arguments[0];
    }

    const ref = firebase
        .storage()
        .ref(path);

    let files;
    try {
        files = await ref.list();
    } catch(ex) {
        t.print('ls: ocorreu um erro para listar o diretorio.');
        return t.done();
    }
    
    const toPrint = [
        ...files.prefixes.map((dir) => `<span style="color: blue;">${dir.name}/</span>`),
        ...files.items.map((item) => item.name)
    ];

    t.print(toPrint.join('&emsp;&emsp;&emsp;'));

    return t.done();
};

const cd = async (t: TerminalApi, $0: string) => {
    const command = extractArguments($0, -1);

    if (command.arguments.length > 1) {
        t.print("cd: muitos argumentos");
        return t.done();
    }

    if (command.arguments.length != 1) {
        t.print('cd: parametro diretorio é obrigatorio');
        return t.done();
    }

    const argument = command.arguments[0];
    if (argument == '.') return;

    let path = argument.startsWith('/') ? 
    argument :
        `${t.getCurrentPath().replace(/$\//, '')}/${argument}`;

    if (argument == '~') path = t.getUserHome();
    if (argument == '..') path = t.getCurrentPath()
        .replace(/\/$/g, '')
        .substring(0, t.getCurrentPath().replace(/\/$/g, '').lastIndexOf('/'));

    try {
        if (await fs.isDirectory(path)) {
            t.setCurrentPath(path);
            return t.done();
        }

        t.print(`cd: ${path} não existe`);
        return t.done();
    } catch (ex) {
        if (ex instanceof FileNotFoundError) {
            t.print(`cd: ${path} não existe`);
            return t.done();
        }

        t.print(`cd: permissão negada para ${path}`);
    }
};

const edit = async(t: TerminalApi, $0: string) => {
    const command = extractArguments($0, 1);

    if (command.arguments.length > 1) {
        t.print("edit: muitos argumentos");
        return t.done();
    }

    if (command.arguments.length != 1) {
        t.print('edit: parametro diretorio é obrigatorio');
        return t.done();
    }
    
    const file = command.arguments[0];

    let path = file.startsWith('/') ? 
        file :
        `${t.getCurrentPath().replace(/$\//, '')}/${file}`;

    let fileContents = "";
    let fileIsNew = true;
    try {
        if (await fs.fileExists(path)) {
            fileContents = await fs.read(path);
        } else {
            await fs.write(path, "")
            fileIsNew = true;
        }
    } catch (ex) {
        if (ex instanceof IsDirectoryError) {
            t.print(`edit: ${file} é um diretorio`);
            return t.done();
        }

        t.print(`edit: permissão negada para ${file}`);
        return t.done();
    }

    const contentOption = command.options.find((option) => option.name == 'content');
    let fileEdited = contentOption?.value || null;
    if (!fileEdited) {
        fileEdited = await t.editFile(fileContents, path);
    }

    if (fileIsNew && !fileEdited) {
        await fs.delete(path);
        return t.done();
    }

    await fs.write(path, fileEdited);
    return t.done();
};

const cat = async(t: TerminalApi, $0: string) => {
    const args = $0.match(/cat(?: | )([\w-\/_\.]*)(.*)/);
    if (args && args[2] !== "") {
        t.print("cat: muitos argumentos para cat");
        return t.done();
    }

    if (!args || args[1] == "") {
        t.print('cat: parametro arquivo faltando.')
        return t.done();
    }

    let file = args[1];
    file = file.startsWith('/') ? file : `${t.getCurrentPath().replace(/\/$/g,'')}/${file}`;
    file = file.replace(
        t.state().firebase.auth.email.match(/(.*)(?:[@]+)/)[1],
        t.state().firebase.auth.uid
    );

    let fileContents;
    try {
        const fileURL = await firebase
            .storage()
            .ref(file)
            .getDownloadURL();

        fileContents = await (await fetch(fileURL)).text();
    } catch (ex) {
        t.print(`cat: ${file} não existe, permissão negada ou não é um arquivo.`)
        return t.done();
    }

    t.print(fileContents, false, {
        pre: true
    });
}

const mkdir = async(t: TerminalApi, $0: string) => {
    const args = $0.match(/mkdir(?: | )([\w-\/_\.]*)(.*)/);
    if (args && args[2] !== "") {
        t.print("mkdir: muitos argumentos");
        return t.done();
    }

    if (!args || args[1] == "") {
        t.print('mkdir: parametro diretorio faltando.')
        return t.done();
    }

    let directory = args[1];
    directory = directory.startsWith('/') ? directory : `${t.getCurrentPath().replace(/\/$/g,'')}/${directory}`;
    directory = directory.replace(
        t.state().firebase.auth.email.match(/(.*)(?:[@]+)/)[1],
        t.state().firebase.auth.uid
    );

    const file = `${directory}/.`

    try {
        await firebase
            .storage()
            .ref(`${directory}/.`)
            .getDownloadURL();
        
        t.print('mkdir: diretorio já existe');
        return t.done();
    } catch(ex) {}

    try {
        await firebase
            .storage()
            .ref(`${directory}/.`)
            .putString(`directory`)
    } catch (ex) {
        t.print('mkdir: permissão negada')
    }
}

const rm = async(t: TerminalApi, $0: string) => {
    const command = extractArguments($0, 1);

    if (command.arguments.length != 1) {
        t.print("rm: você precisa informar um arquivo/diretorio");
        return t.done();
    }

    let file = command.arguments[0];
    let recursive = false;
    if (command.options.find(option => option.name == "r")) {
        recursive = true;
    }

    file = file.startsWith('/') ? file : `${t.getCurrentPath().replace(/\/$/g,'')}/${file}`;
    file = file.replace(
        t.state().firebase.auth.email.match(/(.*)(?:[@]+)/)[1],
        t.state().firebase.auth.uid
    );

    try {
        await fs.delete(file, recursive)
        return t.done();
    } catch(ex) {
        if (ex instanceof IsDirectoryError) {
            t.print("rm: para excluir um diretorio e seus arquivos/diretorios use a flag -r")
            return t.done();
        }

        if (ex instanceof FileNotFoundError) {
            t.print(`rm: arquivo ${command.arguments[0]}/diretorio não existe`);
            return t.done();
        }

        t.print(`rm: permissão negada para ${command.arguments[0]}`);
        return t.done();
    }
}

export default {
    ls: ls,
    cd: cd,
    rm: rm,
    cat: cat,
    edit: edit,
    mkdir: mkdir,

    pwd: (t: TerminalApi) => t.print(t.getCurrentPath().replace(
        t.state().firebase.auth.uid,
        t.state().firebase.auth.email.match(/(.*)(?:[@]+)/)[1]
    )),

    browser: (t: TerminalApi) => {
        t.openBrowser(websites, MosaicHomePage);
    },
    
    clear: (t: TerminalApi) => t.clear(),

    logout: async(t: TerminalApi) => {
        await t.print('Deslogando...', true);
        t.dispatch((setLoading(true)));
        firebase.auth().signOut();
    },

    "script-run": async(t: TerminalApi) => {
        const script = t.getScript();
        if (!script) return;
        t.clear();
        await (new script(t)).run();
        t.print('oi');
    },

    "_run_function": async(t: TerminalApi, $0: string, callback?: () => Promise<any>) => {
        if (!callback) return;
        await callback();
    },

    nothing: (t: TerminalApi) => {}
}