import React from 'react';
import { Switch, Route, BrowserRouter as Router, Link } from 'react-router-dom';

import { Provider } from 'react-redux';
import Game from './game/Game';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './shared/createStore';
import {
  ReactReduxFirebaseProvider
} from 'react-redux-firebase'
import firebase from 'firebase/app';
import 'firebase/analytics';
import './App.css';
import 'firebase/storage';
import settings from './shared/settings';
import Terminal from './terminal/Terminal';
import Dashboard from './dashboard/Dashboard';
import Maker from './modules/gamemode/Maker';

const configs = {
  firebase: settings.firebase,
  firebaseRedux: {
    userProfile: 'users',
    useFirestoreForProfile: true
  }
}

firebase.initializeApp(configs.firebase);
firebase.analytics();

const {store, persist} = configureStore({});

const firebaseReduxProps = {
  firebase,
  config: configs.firebaseRedux,
  dispatch: store.dispatch
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<p>Carregando...</p>} persistor={persist}>
        <ReactReduxFirebaseProvider {...firebaseReduxProps}>
          <Router>
            <Switch>
              <Route path="/" exact={true}>
                  <Dashboard />
              </Route>

              <Route path="/story">
                <div>
                  <Link to="/"><div className="dashboard-button">Voltar para menu principal</div></Link>
                  <Game />
                </div>
              </Route>

              <Route path="/freestyle">
                <div>
                  <Link to="/"><div className="dashboard-button">Voltar para menu principal</div></Link>
                  <Game freestyle={true} />
                </div>
              </Route>

              <Route path="/gamemode/:id">
                  <div>Em Breve</div>
              </Route>

              <Route path="/gamemode/:id/edit">
                <Maker />
              </Route>

              <Route path="/remote-workspace.html">
                <Game />
              </Route>
            </Switch>
          </Router>
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  );
}


export default App;
