import TerminalApi from "../../../terminal/TerminalApi";
import { Terminal } from "../../../terminal/Terminal";

const help = async (t: TerminalApi) => {
    t.print(`centctrl para controlar sua Cent92.

        centctrl config   Ver local de arquivo de configuração
    `);

    return t.done();
}

const configLocale = async (t: TerminalApi) => {
    t.print(`Carregando arquivo .ini de configuração do diretorio: ${t.getUserHome()}cent92/
    `);
    return t.done();
}

export default async (t: TerminalApi, $0: string) => {    
    const args = $0.replace('centctrl ', '');

    switch(args) {
        case 'config':
            return await configLocale(t);
        default:
            return await help(t);
    }

    return t.done();
};