import { Action } from "redux";
import { CHANGE_SCENE, FIX_HARDWARE, LOGIN_ERROR, USER_AUTHENTICATED, SET_LOADING } from "./actions";
import { Scene } from "./scene";

const initialState = {
    scene: Scene.LOGIN,
    loading: false,
    projectManager: {
        hardwareProblem: true
    },
    auth: {
        errorCode: null,
        errorMessage: null,
        authenticated: false
    }
}

const setLoading = (state: any, action: any) => ({
    ...state,
    loading: action.loading
})

/*
* Scenes
*/
const changeScene = (state: any, action: any) => ({
    ...state,
    scene: action.scene
});

/*
* ProjectManager
*/
const fixHardware = (state: any) => ({
    ...state,
    projectManager: {
        ...state.projectManager,
        hardwareProblem: false
    }
});

export default (state = initialState, action: Action) => {
    switch(action.type) {
        case SET_LOADING:
            return setLoading(state, action);

        case CHANGE_SCENE:
            return changeScene(state, action);

        /**
         * ProjectManager
         */
        case FIX_HARDWARE:
            return fixHardware(state);

        default:
            return state;
    }
}