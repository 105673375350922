import * as React from 'react';
import BackgroundText, { LETTER_DURACTION_ANIMETED } from '../../../story/backgroundText/BackgroundText';
import { connect } from 'react-redux';
import { changeScene } from '../../actions';
import { Scene } from '../../scene';



export default connect()((props: any) => {    
    const [render, setRender] = React.useState<any>({});

    return (
        <BackgroundText backgroundImage="">
            {`¢Obrigado por me ajudar a testar essa versão de desenvolvimento.
            Por favor, me mande uma mensagem falando o que você achou!
            
            :)
            
            Para resetar e começar o jogo novamente, rode no seu console do devTools do navegador:
            <span style="text-transform: none;">localStorage.removeItem('persist:root');</span>
            `}
        </BackgroundText>
    )
});