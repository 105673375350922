import { Scene } from './scene';
import { ExtendedFirebaseInstance, ExtendedAuthInstance, ExtendedStorageInstance } from 'react-redux-firebase';
import { Dispatch } from 'redux';

type Firebase = () => ExtendedFirebaseInstance & ExtendedAuthInstance & ExtendedStorageInstance
 
/**
 * Loading
 */
export const SET_LOADING = 'SET_LOADING';
export const setLoading = (loading: boolean) => ({
    type: SET_LOADING,
    loading
});

/**
 * Scenes
 */
export const CHANGE_SCENE = 'CHANGE_SCENE';

export const changeSceneLocal = (scene: Scene) => ({
    type: CHANGE_SCENE,
    scene
});

export const changeScene = (scene: Scene) => (dispatch: Dispatch, getState: any, {getFirebase}: {getFirebase: Firebase}) => {
    const state = getState();
    
    dispatch(setLoading(true));
    getFirebase()
        .firestore()
        .collection(`users`)
        .doc(state.firebase.auth.uid)
        .set({scene}, {merge: true})
        .finally(() => {
            dispatch(setLoading(false));
        });
}

/**
 * Authentication
 */
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const LOGIN_WITH_GITHUB = 'LOGIN_WITH_GITHUB';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const loginError = (errorCode: string|null, errorMessage: string|null) => ({
    type: LOGIN_ERROR,
    errorCode,
    errorMessage
});

export const logout = () => (dispatch: Dispatch, getState: any, {getFirebase}: {getFirebase: Firebase}) => {
    return getFirebase()
        .auth()
        .signOut();
}

/**
 * ProjectManager
 */
export const FIX_HARDWARE = 'FIX_HARDWARE';
export const CREATE_USER_HOME = 'CREATE_USER_HOME';
export const FETCHED_USER_HOME = 'FETCHED_USER_HOME';

export const fixHardware = () => ({
    type: FIX_HARDWARE
});

export const fetchedUserHome = (files: string) => ({
    type: FETCHED_USER_HOME,
    files: files
});

export const fetchUserHome = () => (dispatch: Dispatch, getState: any, {getFirebase}: {getFirebase: Firebase}) => {
    const state = getState();

    return getFirebase()
        .storage()
        .ref(`home/${state.firebase.auth}`);
};