import bash from "./bash";
import ftp from "./ftp";
import gamemodeCommand from "../../modules/gamemode/gamemodeCommand";
import packageCommand from "../../core/package/packageCommand";

export default {
    ...bash,
    ...ftp,
    ...gamemodeCommand,
    ...packageCommand
}