import Script from "../../terminal/Script";
import TerminalApi from "../../terminal/TerminalApi";
import settings from "../../shared/settings";
import firebase from "firebase";
import { changeScene } from "../actions";
import { Scene } from "../scene";

export default class LoginScript implements Script {
    constructor(private t: TerminalApi) {}

    async run() {
        window.location.href = '/';
        
        return this.t.done();

        await this.t.print(`Seja bem vindo ao ${settings.project.name}
            O Jogo para profissonais de TI.

        `, true);
        await this.t.print(`
            Antes de começar no jogo, você precisa se autenticar, assim poderemos
            salvar o seu progresso e você podera jogar de qualquer lugar.
        `, false);

        return this.requestAction();
    }

    async info() {
        this.t.print(`
            ${settings.project.name} é um jogo com desafios que irão testar suas skills
            de profissional de TI e com uma historia envolvente.

            Criado por Rafael Lopes Dantas.
            http://rafaeldantas.me
            wgrafael@rafaeldantas.me

            
        `);

        this.requestAction();
    }

    async loginWithGoogle() {
        await this.t.print('Chamando Google...', true);
        this.t.print('Processando login... <br /> Isso pode levar alguns minutos.');
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase
            .auth()
            .signInWithPopup(provider)
            .catch((error) => {
                this.t.print(`
                    Ops, ocorreu um erro ao fazer o login.
                    Tente novamente ou entre em contato.

                    <span style="color: red;">Erro:</span> ${error.message}
                `, false);
                return this.requestAction();
            });
    }

    async loginWithGithub() {
        await this.t.print('Chamando Github...', true);
        this.t.print('Processando login... <br />Isso pode levar alguns minutos.');

        const provider = new firebase.auth.GithubAuthProvider();

        return firebase
            .auth()
            .signInWithPopup(provider)
            .catch((error) => {
                this.t.print(`
                    Ops, ocorreu um erro ao fazer o login.
                    Tente novamente ou entre em contato.

                    <span style="color: red;">Erro:</span> ${error.message}
                `, false);
                return this.requestAction();
            });
    }

    async requestAction(): Promise<any> {
       this.t.setTerminalHelp(`
            <h2>Seja bem vindo!</h2>
            <p>
                Este é o ${settings.project.name}, o jogo pensado para profissionais de TI.
            </p>
            <br /><br />
            <p>
                Agora você precisa autenticar-se, abaixo você pode clicar para digitar o
                comando automaticamente no terminal ou digitar você mesmo.
            </p>
            <br ><br />
            <div>
                <a data-command="entrar github" class="command">Entrar com GitHub</a>
                <a data-command="entrar google" class="command">Entrar com Google</a>
                <a data-command="info" class="command">O que é ${settings.project.name}?</a>
            </div>
        `);

        this.t.print(`
            entrar github - Entrar com github
            entrar google - Entrar com o google
            info - O que é o ${settings.project.name}
        `)
        await this.t.print("Como deseja se autenticar?", true);
        const action = await this.t.input();
        
        switch(action) {
            case 'entrar google':
                return this.loginWithGoogle();

            case 'entrar github':
                return this.loginWithGithub();

            case 'info':
                return this.info();

            default:
                return this.requestAction();
        }

    }
    
}