import firebase from "firebase";
import { PermissionDeniedError, UnknownError } from "./exceptions";
import events from "./events";
import { FileEvents, FileWrited, FileRead, FileRemoved, DirectoryRemoved, FileNotFoundError, IsDirectoryError, Filesystem, FileAlreadyExistsError } from "./filesystem";

/*export class FileCreated implements Event {
    constructor(public fileName: string) {}
}*/

export class FilesystemFirebase implements Filesystem {
    async write(path: string, content: string): Promise<boolean> {
        try {
            await firebase
                .storage()
                .ref(path)
                .putString(content)

            events.dispatch(FileEvents.FileWrited, {
                path,
                content
            } as FileWrited)

            return true;
        } catch (ex) {
            throw this.throwErrorByError(ex);
        }
    }

    async read(path: string): Promise<string> {
        try {
            const url = await firebase
                .storage()
                .ref(path)
                .getDownloadURL();

            events.dispatch(FileEvents.FileRead, {
                path
            } as FileRead);

            return await (await fetch(url)).text();
        } catch (ex) {
            throw this.throwErrorByError(ex);
        }
    }

    async delete(path: string, recursive = false): Promise<boolean> {
        try {
            await firebase
                .storage()
                .ref(path)
                .delete();

            events.dispatch(FileEvents.FileRemoved, {
                path
            } as FileRemoved);

            return true;
        } catch(ex) {
            if (ex.code == "storage/object-not-found" && this.isDirectory(path)) {
                if (!recursive) throw new IsDirectoryError();
                return this.deleteDirectory(path);
            }

            throw this.throwErrorByError(ex);
        }
    }

    private async deleteDirectory(path: string): Promise<boolean> {
        const ref = firebase
            .storage()
            .ref(path);

        let list;
        try {
            list = await ref.listAll();
        } catch (ex) {
            throw this.throwErrorByError(ex);
        }

        if (list.items.length > 0 || list.prefixes.length > 0) {
            for(const directory of list.prefixes) {
                await this.deleteDirectory(directory.fullPath);
            }

            for(const file of list.items) {
                await this.delete(file.fullPath);
            }

            events.dispatch(FileEvents.DirectoryRemoved, ({
                path: path
            } as DirectoryRemoved))

            return true;
        }

        throw new FileNotFoundError();
    }

    move(path: string, origin: string): Promise<boolean> {
        throw new Error('Method not implemented.')
    }

    async copy(path: string, origin: string, force: boolean = false): Promise<boolean> {
        if (await this.fileExists(origin) && force) {
            throw new FileAlreadyExistsError();
        }

        const fileContents = await this.read(path);
        await this.write(origin, fileContents);

        return true;
    }

    async isDirectory(path: string): Promise<boolean> {
        try {
            const list = await firebase
                .storage()
                .ref(path)
                .listAll();

            return (list.items.length > 0 || list.prefixes.length > 0)
        } catch (ex) {
            return false;
        }
    }

    async fileExists(path: string) {
        try {
            await firebase
                .storage()
                .ref(path)
                .getDownloadURL();

            return true;
        } catch(ex) {            
            if (ex.code == "storage/object-not-found") {
                return false;
            }

            if (await this.isDirectory(path)) throw new IsDirectoryError();

            throw this.throwErrorByError(ex);
        }
    }

    private throwErrorByError(ex: any) {
        switch(ex.code) {
            case 'storage/unauthorized':
                return new PermissionDeniedError(ex.message);

            case 'storage/object-not-found':
                return new FileNotFoundError(ex.message);

            default:
                return ex;
        }
    }
}