import React, { FunctionComponent } from 'react';
import { PrintOptions } from './Terminal';
import './Line.css';

export const LINE_ANIMATED_LETTER_DURACTION = 50;

type Props = {
    animated?: boolean,
    children: any,
    options: PrintOptions
}

const Line: FunctionComponent<Props> = ({
    children,
    animated = false,
    options = {}
}) => {
    const [animatedText, setAnimatedText] = React.useState<Array<string>>([]);
    React.useEffect(() => {
        const addLetter = (letter: string) => {
            setAnimatedText([...animatedText, letter]);
        };

        const anime = () => {
            if (animatedText.length === children.length) return;
            let letter = children.slice(animatedText.length, animatedText.length+1);

            if (letter === '¢' || letter === '\n') {
                letter = '<br />';
            }

            addLetter(letter);
        };

        setTimeout(anime, LINE_ANIMATED_LETTER_DURACTION);
    });

    const style = {
        color: options.color
    }

    const className = `line ${options.blink ? 'blink' : ''}`

    if (animated) {
        return (
            <p className={className} style={style}>
                <span dangerouslySetInnerHTML={{__html: animatedText.join('')}}></span>
                {animatedText.length !== children.length ? (
                    <span className="cursor"></span>
                ) : null}
            </p>
        )
    }

    return (
        <>
        {!options.pre ? (
            <p className={className} style={style} dangerouslySetInnerHTML={{__html: children.replace(/¢\n/g, "<br />").replace(/¢/g, "<br />").replace(/\n/g, '<br />') }} />
        ) : (
            <pre className={className} style={style}>{children}</pre>
        )}
        </>
    );
};

export default Line;