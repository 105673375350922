import * as React from 'react';
import BackgroundText, { LETTER_DURACTION_ANIMETED } from '../../../story/backgroundText/BackgroundText';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeScene } from '../../actions';
import { Scene } from '../../scene';

let parts = [
    {
        text: ` Desconhecido na porta: Ei $name, você precisa vir aqui urgente, é com o seu pai.`,
        background: ''
    },
    {
        text: ` Cheguei guiado pelo desconhecido que me chamou e hávia uma mutidão no patio.`,
        background: ''
    },
    {
        text: ` Cientista chefe: Sinto muito $name.`,
        background: ''
    },
    {
        text: ` Depois de receber um abraço do cientista chefe sem entender, vi meu pai no chão.`,
        background: ''
    },
    {
        text: ` Cientista chefe: Ele pulou da cobertura.`,
        background: ''
    },
    {
        text: ` Um ano depois... (1995)`,
        background: ''
    },
    {
        text: ` Finalmente depois de um anos com o meu curso da faculdade trancado, minha mãe me convenceu a voltar e me formar, e agora já estava um pouco mais conformado, mas com muita saudades do meu pai.
                E nesta tarde, finalmente depois de um grande processo burocratico, poderia recuperar as coisas que eram do meu pai na AEB.
        `
    },
    {
        text: ` Cientista chefe: Eu fiz questão de acompanhar os entregadores para trazer as coisas do seu pai. Saiba que ele era um grande homem.`
    },
    {
        text: ` Cientista chefe: Bom, aqui está o computador e a centrifuga atomica, que eram propriedades de seu pai. E também aqui estão as anotações de seu pai.`
    },
    {
        text: ` Então, depois que o pessoal foi embora e eu deixei no porão, desci dar uma fuçana e ver as anotações do meu pai.`
    },
    {
        text: `
        Dia 3: Mostrei os fundamentos para o Jorge, cientista chefe do AEB, e consegui começar uma pequena pesquisa em um laboratorio no AEB.
        ...
        Dia 42: Consegui ganhar meu proprio laboratorio para continuar com minha pesquisa.
        ...
        Dia 82: Agora eu consegui comprar a centrifuga atomica, qual a AEB não estava querendo financiar, vou conseguir acelerar na velocidade da luz, será que vai ser capaz de alterar o espaço-tempo.
        ...
        Dia 236: Consegui acelerar um corpo estático na velocidade da luz.
        ...
        Dia 536: Não vejo resultados no projeto, sou um fracasso.`
    },
    {
        text: ` Mãe: Ei, você não está atrasado para ir para a faculdade?
        
                $name: Estou indo...`
    },
    {
        text: ` Professor: Seja bem vindo de volta $name, espero que esteja tudo bem com você.`
    },
    {
        text: ` Professor: Então, na aula de hoje vamos`
    }
];

export default () => {    
    const [render, setRender] = React.useState<any>({});
    const dispatch = useDispatch();
    const [name]: any = useSelector<any>(state => ([
        state.firebase.auth.displayName
    ]));

    React.useEffect(() => {
        setTimeout(() => {
            if(parts.length < 1) {
                setTimeout(() => {
                    //dispatch(changeScene(Scene.VERIFY_AFTER_BROKEN))
                }, 500);
                return;
            }

            setRender(parts.shift());
        }, (LETTER_DURACTION_ANIMETED*render.text?.length || 1)+5000)
    }, [render, parts]);

    return (
        <>
            {render.text ? (
                <BackgroundText backgroundImage={render.background} backgroundStyle={render.backgroundStyle ? render.backgroundStyle : {}}>
                    {render.text.replace(/\$name/g, name)}
                </BackgroundText>
            ): null}
        </>
        
    )
};