import * as React from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { useSelector } from 'react-redux';

type Props = {
    setErrorMessage: any
};

export default ({setErrorMessage}: Props) => {
    const history = useHistory();
    const [profile]: any = useSelector<any>((state) => ([
        state.firebase.profile
    ]));

    return (
        <>
        <div className="option-card" onClick={() => history.push('/story')}>
            <div className="icon">
                <img src={require('./story.png')} />
            </div>

            <div className="description">
                <h2>Modo Historia</h2>
                <p>Jogue no modo historia e faça vários desafios junto a uma incrível historia de viagem no tempo.</p>
            </div>
        </div>

        <div className="option-card" onClick={() => setErrorMessage('Ops, este modo não está disponível ainda.')}>
            <div className="icon">
                <img src={require('./terminal.png')} />
            </div>

            <div className="description">
                <h2>Modo livre</h2>
                <p>No modo livre você é livre para fazer o que quiser, use a sua imaginação.</p>
            </div>
        </div>

        <div className="option-card" onClick={() => setErrorMessage('Ops, este modo não está disponível ainda.')}>
            <div className="icon">
                <img src={require('./game.png')} />
            </div>

            <div className="description">
                <h2>Mini-jogos</h2>
                <p>Mini-jogos disponível para te desafiar</p>
            </div>
        </div>

        <div className="option-card" onClick={() => firebase.auth().signOut()}>
            <div className="icon">
                <img src={require('./logout.png')} />
            </div>

            <div className="description">
                <h2>Sair</h2>
                <p>Desconectar da sua conta. <br /><small>Logado como {profile.username}</small></p>
            </div>
        </div>
        </>
    )
}