import TerminalApi from "../../terminal/TerminalApi";
import extractArguments from "../../terminal/commands/extractArguments";
import * as pck from './package';
import firebase from "firebase";
import { FilesystemFirebase } from "../filesystem.firebase";

const help = (t: TerminalApi) => {
    t.print(` <span style="color: blue;">Package: Gerenciador de pacotes</span>
        <span style="color: blue;">[ Gerenciador de pacote ]</span>
        package install [pacote]   - Instalar um pacote da store.
        package search [search]    - Pesquisar um pacote na store.
        package remove [pacote]    - Remover um pacote.
        package list               - Listar pacotes instalado.

        <span style="color: blue;">[ Store ]</span>
        package store create       - Criar um pacote no store.
        package store list         - Listar meus pacotes no store.
        package store edit         - Editar um pacote na store.
    `);
};

const listPackages = async(t: TerminalApi) => {
    t.print(`Pacotes instalado:`, false, {
        color: 'blue'
    });

    const packageList = await pck.list();
    for(const packageCommand in packageList) {
        const packageItem = await pck.getPackage(packageList[packageCommand]);
        if (packageItem) {
            await t.print(`${packageList[packageCommand]} - ${packageItem.description}`);
        }
    }
};

const installPackage = async(t: TerminalApi, packageToInstall: string) => {
    if (!packageToInstall) {
        return t.print(`Use: package install [pacote]`);
    }

    const packageItem = await pck.getPackage(packageToInstall);

    if (!packageItem) {
        return t.print(`Pacote não encontrado.`)
    }

    const packageIsInstalled = await (await pck.list()).find((packageItem) => packageItem === packageToInstall);

    if (packageIsInstalled) {
        return t.print(`Você já possui esse pacote.`);
    }

    t.print(`
        <span style="color: green;">[ Instalar Pacote ]</span>
        <strong>Pacote:</strong> ${packageToInstall}
        <strong>Descrição:</strong> ${packageItem.description}
        <strong>Criado por:</strong> ${packageItem.owner}

    `);
    const response = await t.input(null, "Tem certeza que deseja instalar? (y/N): ");

    if (/(yes|sim|y|s)/i.test(response)) {
        await t.print("Instalando...", true);
        try {
            pck.install(packageToInstall);
            return t.print(`${packageToInstall} instalado com sucesso.`);
        } catch(err) {
            return t.print('Ocorreu um erro ao instalar o pacote.', false, {
                color: 'red'
            });
        }
    }

    return t.print('Instalação abortada.');
};

const removePackage = async(t: TerminalApi, packageToRemove: string) => {
    if (!packageToRemove) {
        return t.print('Use: package remove [pacote]');
    }

    const hasPackage = (await pck.list()).find((packageItem) => packageItem === packageToRemove);
    if (!hasPackage) {
        return t.print('Você não tem esse pacote instalado.');
    }

    const packageItem = await pck.getPackage(packageToRemove);
    t.print(`
        Remover pacote ${packageToRemove}
        Descrição: ${packageItem?.description||'Sem descrição.'}
    `);
    const confirm = await t.input(null, 'Tem certeza que deseja remover o pacote? (y/N): ');

    if (/(yes|sim|y|s)/i.test(confirm)) {
        try {
            await t.print('Removendo...', true);
            await pck.remove(packageToRemove);
            return t.print('Pacote removido com sucesso.');
        } catch(err) {
            return t.print('Ocorreu um erro ao remover o pacote.');
        }
    }

    return t.print('Operação abortada.');
};

const searchPackage = async(t: TerminalApi, term: string) => {
    if (!term) {
        return t.print('Use: package search [termo]');
    }

    const packageElements = await pck.search(term);

    t.print(" [ Resultados ] ", false, {
        color: 'green'
    });

    packageElements.forEach((packageItem) => {
        if (packageItem.exists) {
            t.print(`<strong>${packageItem.id}</strong>: ${packageItem.data()?.description}`);
        }
    });
};

const listStorePackage = async(t: TerminalApi) => {
    const packagesInStore = (await firebase
        .firestore()
        .collection("packages")
        .where('owner', '==', t.getUsername())
        .get()).docs
    
    t.print(` [ Seus pacotes na Store ] `, false, {
        color: 'green'
    });

    for(let i in packagesInStore) {
        t.print(`<strong>${packagesInStore[i].id}</strong>: ${packagesInStore[i].data().description}`);
    }
};

const editPackage = async(t: TerminalApi, packageToEdit: string) => {
    if (!packageToEdit) {
        return t.print('Use: package store edit [pacote]');
    }

    const packageItem = await pck.getPackage(packageToEdit);
    console.log(packageItem);
    if (packageItem?.owner != t.getUsername()) {
        return t.print('Você não pode editar um pacote que não é seu.');
    }

    const fs = (new FilesystemFirebase());
    const content = await t.editFile(
        await fs.read(`/system/packages/${packageToEdit}/index.js`),
        `/system/packages/${packageToEdit}/index.js`
    );
    fs.write(`/system/packages/${packageToEdit}/index.js`, content);
};

export default {
    package: (t: TerminalApi, $0: string) => {
        const command = extractArguments($0, -1);
        const action = command.arguments[0];

        if (action == 'list') {
            return listPackages(t);
        }

        if (action == 'install') {
            return installPackage(t, command.arguments[1]);
        }

        if (action == 'remove') {
            return removePackage(t, command.arguments[1]);
        }

        if (action == 'search') {
            return searchPackage(t, command.arguments[1]);
        }

        if (action == 'store' && command.arguments[1] == 'list') {
            return listStorePackage(t);
        }

        if (action == 'store' && command.arguments[1] == 'edit') {
            return editPackage(t, command.arguments[2]);
        }

        help(t);
    },

    pck: (t: TerminalApi, $0: string) => {
        return t.executeCommand(`${$0.replace('pck', 'package')}`);
    },
}