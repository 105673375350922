import * as React from 'react';
import './Browser.css';
import { BrowserApi } from './BrowserApi';

export type WebSiteProps = {
    url: string,
    browserApi: BrowserApi
}

type Props = {
    webSites: {[key: string]: React.ComponentType<WebSiteProps>},
    homepage?: string,
    close: () => any
}

type State = {
    loading: boolean,
    title: string,
    url: string,
    hostname: string,
    element: React.ComponentType<WebSiteProps>,
    step: string,
    browserHelp: string|null
}

const HomePageSite: React.FunctionComponent<WebSiteProps> = (props) => (
    <p>Pagina Inicial.</p>
);

export const NotFoundWebSite: React.FunctionComponent<WebSiteProps> = (props) => (
    <p>URL {props.url} não foi encontrado</p>
);

export class Browser extends React.Component<Props, State> {
    pageFrame = React.createRef<HTMLIFrameElement>();
    webSiteInput = React.createRef<HTMLInputElement>();
    browserHelp = React.createRef<HTMLDivElement>();
    browserApi = new BrowserApi(this);
    state = {
        loading: false,
        title: 'Loading...',
        url: "",
        hostname: '',
        element: (props: WebSiteProps) => (<></>),
        step: '',
        browserHelp: null,
        eventsSubscribe: []
    }

    setPageTitle(title: string) {
        this.setState({
            title
        });
    }

    setBrowserHelp(browserHelp: string) {
        this.setState((prevState) => ({...prevState, browserHelp}))
    }

    async changePage(site: string|null = null) {
        const newSite = site || this.webSiteInput.current?.value || '';
        if (newSite === this.state.url) return;
        if (newSite === '') return;
        let [fullSite, protocol, hostname] = newSite.match(/^(?:([a-z]*)(?:\:\/\/))?(?:www.)?([^\/?#]+)(?:[\/?#]|$)(?:.*)/i) || ['http://invalid-url', 'http://', 'invalid-url']
        fullSite = protocol ? fullSite : `http://${fullSite}`;
        protocol = protocol || 'http';

        if (hostname == 'homepage') {
            this.setState({
                title: 'Pagina Inicial',
                url: '',
                element: HomePageSite
            })
            return;
        }

        // fake status loading
        const randFakeTime = () => Math.floor((Math.random() * (2000 - 500)) + 1000);

        this.setState({step: 'Connecting to Http Server...', loading: true});
        await new Promise((resolve, reject) => setTimeout(resolve, randFakeTime()));

        this.setState({step: 'Reading response...'});
        await new Promise((resolve, reject) => setTimeout(resolve, randFakeTime()));

        if (this.state.hostname === hostname) {
            this.setState({
                url: fullSite,
                loading: false,
                step: ''
            });
            return;
        }

        const element = this.props.webSites[`${protocol}://${hostname}`];
        if (!element || hostname == '') {
            // not found page
            this.setState({
                title: 'Not Found',
                url: `${fullSite}`,
                element: NotFoundWebSite,
                loading: false,
                step: ''
            });
            return;
        }

        this.setState({
            title: "Untitled",
            url: fullSite,
            element,
            loading: false,
            step: ''
        });
    }

    componentDidUpdate() {
        if (this.webSiteInput.current && !this.state.loading) this.webSiteInput.current.value = this.state.url;

        if (this.browserHelp.current) {
            const elements: Array<HTMLAnchorElement> = (this.browserHelp.current.getElementsByClassName('action') as any);
            [...elements].forEach((action) => {
                const newAction = action.cloneNode(true);
                action.parentNode?.replaceChild(newAction, action);
                newAction.addEventListener('click', (event) => {
                    this.changePage(action.dataset.website);
                }, false);
            });
        }
    }

    componentDidMount() {
        if (this.props.homepage) {
            this.changePage(this.props.homepage);
        } else {
            this.changePage('http://homepage');
        }

        if (this.webSiteInput.current) this.webSiteInput.current.value = this.state.url;
    }

    render() {
        return (
            <div className="window-browser">
                <div className="browser">
                    <div className="bartitle">
                        <p className="title">NCSA Mosaic</p>
                        <div className="actions">
                            <img src={require('./win-actions.png')} onClick={() => {this.props.close()}} />
                        </div>
                    </div>

                    <div className="top">
                        <div className="menu">
                            <span className="with-keyshurtcut">File</span>
                            <span className="with-keyshurtcut">Edit</span>
                            <span className="with-keyshurtcut">Options</span>
                            <span className="with-keyshurtcut">Navigate</span>
                            <span>Hotlist</span>
                            <span>Annotate</span>
                        </div>
                        <img src={require('./win-buttons.png')} width="auto" height="30px" />
                    </div>

                    <hr />
                    
                    <div className="navigation">
                        <div className="document-info">
                            <div className="document-title">
                                <span>Document Title:</span>
                                <input type="text" value={this.state.title} disabled={true} />
                            </div>
                            <div className="document-url">
                                <span>Document URL:</span>
                                <input
                                    ref={this.webSiteInput}
                                    onKeyUp={(event) => event.key === "Enter" ? this.changePage() : null}
                                    type="text"
                                    disabled={this.state.loading}
                                />
                            </div>
                        </div>

                        <div className="navegate-button" onClick={() => this.changePage()}>
                            <img src={this.state.loading ? require('./mosaic-logo.gif') : require('./mosaic-logo-static.png')} />
                        </div>
                    </div>

                    <hr />
                    
                    <div className="page-content">
                        <this.state.element url={this.state.url} browserApi={this.browserApi} />
                    </div>

                    <div className="load-info">
                        {this.state.step ? this.state.step : (
                            <p>&emsp;</p>
                        )}
                    </div>
                </div>
                {this.state.browserHelp ? (<div ref={this.browserHelp} className="browser-help" dangerouslySetInnerHTML={{__html: this.state.browserHelp || ""}}></div>) : null}
            </div>
        )
    }
}

export default Browser;