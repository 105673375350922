import * as React from 'react';
import { useSelector } from 'react-redux';
import './Dashboard.css';
import Login from './Login';
import Minigames from './Minegames';
import './dash_background.png';
import settings from '../shared/settings';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import Menu from './Menu';

export default () => {
    const [auth, profile]: any = useSelector<any>(state => ([
        state.firebase.auth,
        state.firebase.profile
    ]));
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const usernameRef = React.useRef<any>();

    React.useEffect(() => {
        (async() => {
            if (!(await firebase.auth().currentUser?.getIdTokenResult())?.claims.username) {
                await firebase.functions().httpsCallable("putUsernameInToken")();
                await firebase.auth().currentUser?.getIdToken(true);
            }
        })();
    });

    if (!auth.isLoaded || !profile.isLoaded) {
        return (
            <div className="loading">
                <div className="overlay" />
                <h2 className="title">Carregando</h2>
            </div>
        )
    }

    if ((auth && auth.isEmpty) || (profile && !profile.alphaKey)) {
        return <Login />
    }

    const createUsername = async() => {
        const username = usernameRef.current.value;
        setErrorMessage("");
        
        if (!username) {
            setErrorMessage("Você precisa informar um nome de usuário.");
            return;
        }

        if (!/^(?=.{4,16}$)[a-zA-Z0-9._]*$/.test(username)) {
            setErrorMessage(`
                Nome de usuário inválido! <br />
                - Deve ter no mínimo 4 caracteres e no maximo 16. <br />
            `);
            return;
        }

        setLoading(true);
        
        try {
            const result = await firebase.functions().httpsCallable('createUsername')({
                username
            });

            if (!result.data.success) {
                setErrorMessage(result.data.error);
            }
        } catch (err) {
            setErrorMessage('Ocorreu um erro interno, tente novamente mais tarde.');
        }

        setLoading(false);
    }

    return (
        <div className="dashboard">
            {errorMessage !== "" ? (
                <div className="alert-box">
                    <p dangerouslySetInnerHTML={{__html: errorMessage}}></p>
                    <img src={require('./close.png')} onClick={() => setErrorMessage("")} />
                </div>
            ) : null}

            {isLoading ? (
                <div className="loading">
                    <div className="overlay" />
                    <h2 className="title">Aguarde</h2>
                </div>
            ) : null}

            <div className="image"></div>
            <div className="options">
                <h2 className="name">{settings.project.name}<small>Alpha</small></h2>

                {profile.username ? <Menu setErrorMessage={setErrorMessage}/> : (
                    <div className="username-required">
                        <form onSubmit={(event) => {event.preventDefault(); createUsername()}}>
                        <p>Antes de continuar, você precisa criar um nome de usuário.</p>
                        <input
                            type="text"
                            placeholder="Nome de usuário"
                            ref={usernameRef}
                            value={username}
                            onChange={(event) => setUsername(event.currentTarget.value)}
                        />

                        {/^.{4,16}$/.test(username) ? null : (
                            <p>❌ Nome de usuário deve ter entre 4 e 16 caracteres.</p>
                        )}

                        {/^[a-zA-Z0-9._]*$/.test(username) ? null : (
                            <p>❌ Nome de usuário deve não pode ter caracteres especiais.</p>
                        )}
                        

                        <button disabled={!/^(?=.{4,16}$)[a-zA-Z0-9._]*$/.test(username)} type="submit">Criar usuário</button>
                        </form>
                    </div>
                )}
                
            </div>
        </div>
    )
};