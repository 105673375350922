import * as React from 'react';
import './Login.css';
import settings from '../shared/settings';
import './background.jpg';
import firebase, { auth } from 'firebase';

export default () => {
    const [ errorMessage, setErrorMessage ] = React.useState("");
    const [ isLoading, setLoading ] = React.useState(false);
    const alphaKey = React.useRef<any>();

    const githubLogin = async() => {
        const provider = new firebase.auth.GithubAuthProvider();
        login(provider);
    }

    const googleLogin = async() => {
        const provider = new firebase.auth.GoogleAuthProvider();
        login(provider);
    }

    const login = async(provider: firebase.auth.AuthProvider) => {
        setErrorMessage("");
        setLoading(true);

        try {
            await firebase.auth().signInWithPopup(provider);
        } catch(error) {
            setErrorMessage(`
                Ops, ocorreu um erro ao tentar efetuar o login!<br />
                ${error}
            `)
        }

        setLoading(false);
    }

    const validateAlphaKey = async() => {
        const key = alphaKey.current.value;

        if (!key) {
            setErrorMessage("Você precisa digitar uma chave alpha para ativar.");
            return;
        }

        setLoading(true);
        try {
            const result = await firebase.functions().httpsCallable("isValidKeyAlpha")({
                key
            });

            if (!result.data.success) {
                setErrorMessage(result.data.error);
                setLoading(false);
            }
        } catch (error) {
            setErrorMessage('Ocorreu um erro interno para validar a chave alpha.')
            setLoading(false);
        } 
    }

    return (
        <div className="login-page">
            {errorMessage !== "" ? (
                <div className="alert-box">
                    <p dangerouslySetInnerHTML={{__html: errorMessage}}></p>
                    <img src={require('./close.png')} onClick={() => setErrorMessage("")} />
                </div>
            ) : null}

            {isLoading ? (
                <div className="loading">
                    <div className="overlay" />
                    <h2 className="title">Aguarde</h2>
                </div>
            ) : null}
        
            <h2 className="project-title">{settings.project.name} <small className="beta">alpha</small></h2>
        
            <div className="login-container">
            {firebase.auth().currentUser ? (
                <div className="beta-required">
                    <h2>Olá {firebase.auth().currentUser?.displayName || ''}</h2>
                    <p>
                        Sua conta não está ativada para usar a versão alpha, insira uma chave alpha abaixo para   
                        ativar sua conta antes do lançamento. <br /><br />
                        <input type="text" placeholder="Chave alpha" ref={alphaKey} />
                    </p>
                    <a className="login-button" onClick={() => validateAlphaKey()}>Ativar</a>
                    <a className="login-button" onClick={() => firebase.auth().signOut()}>Sair</a>
                </div>
            ) : (
                <>
                    <a className="login-button github" onClick={() => githubLogin()}><img src={require('./github.png')} /> Entrar com Github</a>
                    <a className="login-button google" onClick={() => googleLogin()}><img src={require('./google.png')} /> Entrar com Google</a>
                </>
            )}
            </div>
        </div>
    )
};