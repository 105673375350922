import TerminalApi from "../TerminalApi";
import extractArguments from "./extractArguments";
import { FilesystemFirebase } from "../../core/filesystem.firebase";
import { doesNotMatch } from "assert";
import { PermissionDeniedError } from "../../core/exceptions";
import { FileNotFoundError } from "../../core/filesystem";
import { getDiffieHellman } from "crypto";

const fs = new FilesystemFirebase();
const systemFtpPath = '/system/ftp';

class FtpCommand {
    private host = '';
    private currentPath = '';
    private fs = '';

    constructor(private t: TerminalApi, private $0: string) {}

    async executeCommand() {
        const command = extractArguments(this.$0, 1);

        if (command.options.find(option => option.name == 'help')) {
            this.t.print(`
                Use: ftp host
    
                Para obter ajuda sobre os comandos FTP, depois de conectado, digite
                ?.
            `);
            return this.t.done();
        }
    
        if (command.arguments.length !== 1) {
            this.t.print('ftp: informe o host para connectar');
            return this.t.done();
        }

        // Efeito "conectando"
        await this.t.delay(1000);
    
        const host = command.arguments[0].replace('ftp://', '');

        try {
            await this.connectToServer(host);
            
        } catch (ex) {
            if (ex instanceof PermissionDeniedError) {
                this.t.print(`ftp: Usuário ou senha é inválido.`);
                return this.t.done();
            }

            this.t.print(`ftp: Servidor ${host} não econtrado.`);
            return this.t.done();
        }

        await this.requestAction();
    }

    async connectToServer(host: string) {
        try {
            if (!await fs.fileExists(`${systemFtpPath}/${host}/ftp.server`)) {
                throw new FileNotFoundError();
            }
        } catch (ex) {
            if (ex instanceof PermissionDeniedError) {
                throw new FileNotFoundError();
            }

            throw ex;
        }
        

        this.host = host;
    
        const username = await this.t.input(null, "Usuário: ");
        // @todo: Por enquanto só consegue logar com o proprio usuário,
        // implemetar algum esquema no ftp para ter controle melhor de usuário
        // no futuro
        // Implementar no futuro tambḿe login com usuario anonimo
        if (username !== this.t.getUsername()) {
            throw new PermissionDeniedError();
        }
    
        // @todo: implemetar senha, hoje é qualquer uma haha
        await this.t.input(null, "Senha: ");

        if (!await fs.isDirectory(`${systemFtpPath}/${host}${this.t.getUserHome()}`)) {
            await fs.write(`${systemFtpPath}/${host}${this.t.getUserHome()}/.`, " ");
        }

        this.currentPath = `${this.t.getUserHome()}`;
    }

    help() {
        this.t.print(`
            help (ou ?) - Obter ajuda sobre os comandos.
    
            get - Obter um arquivo.
            put - Copiar um arquivo da sua maquina para o servidor de ftp.
            delete - Remover um arquivo no diretorio do servidor de ftp.
            cd - Mudar o diretorio no servidor de ftp.
            ls - Listar os arquivos no servidor de ftp.
            pwd - Ver caminho atual no servidor de ftp.
    
            lcd - Mudar o diretorio da sua maquina
            lls - Lista o diretoria atual da sua maquina
            lpwd - Ver caminho atual no servidor de ftp
    
        
            quit (ou bye) - Encerrar a conexão com o servidor.
        `);
        return this.t.done();
    }

    async getFile(file: string) {
        if (!file) {
            this.t.print(`
                use: get [arquivo no servidor de ftp]
            `);
            return this.t.done();
        }

        try {
            if (!(await fs.fileExists(`${systemFtpPath}/${this.host}/${this.currentPath}/${file}`))) {
                throw new FileNotFoundError();
            }

            if (await fs.fileExists(`${this.t.getCurrentPath()}/${file}`)) {
                this.t.print('553 Arquivo com este nome já existe no diretorio local');
                return this.t.done();
            }

            this.t.print(`
                200 command successful.
                150 Transferindo ${file}
            `);
            await fs.copy(`${systemFtpPath}/${this.host}/${this.currentPath}/${file}`, `${this.t.getCurrentPath()}/${file}`);
            this.t.print(`
                226 Transferencia completada com sucesso
                local: ${file} recebido com sucesso.
            `);
        } catch (ex) {
            if (ex instanceof PermissionDeniedError) {
                this.t.print(`550: permissão negada para ${file}`);
                return this.t.done();
            }

            if (ex instanceof FileNotFoundError) {
                this.t.print(`550: arquivo ${file} não existe no servidor de ftp`);
                return this.t.done();
            }

            this.t.print(`550: falha ao obter o arquivo ${file}`);
            return this.t.done();
        }
    }

    async putFile(file: string) {
        if (!file) {
            this.t.print(`
                use: put [arquivo na sua maquina local]
            `);
            return this.t.done();
        }

        try {
            if (!(await fs.fileExists(`${this.t.getCurrentPath()}/${file}`))) {
                throw new FileNotFoundError();
            }

            if (await fs.fileExists(`${systemFtpPath}/${this.host}/${this.currentPath}/${file}`)) {
                this.t.print('553 Arquivo com este nome já existe no servidor de ftp (overwrite não permitido pelo servidor)');
                return this.t.done();
            }

            this.t.print(`
                200 command successful.
                150 Transferindo ${file}
            `);
            await fs.copy(`${this.t.getCurrentPath()}/${file}`, `${systemFtpPath}/${this.host}/${this.currentPath}/${file}`);
            this.t.print(`
                226 Transferencia completada com sucesso
                local: ${file} enviado com sucesso
            `);
        } catch (ex) {
            if (ex instanceof PermissionDeniedError) {
                this.t.print(`550: permissão negada para ${file}`);
                return this.t.done();
            }

            if (ex instanceof FileNotFoundError) {
                this.t.print(`550: arquivo ${file} não existe na sua maquina local`);
                return this.t.done();
            }

            this.t.print(`550: falha ao obter o arquivo ${file}`);
            return this.t.done();
        }
    }

    async delete(file: string) {
        try {
            await fs.delete(`${systemFtpPath}/${this.host}/${this.currentPath}/${file}`);
        } catch(ex) {
            if (ex instanceof FileNotFoundError) {
                this.t.print(`550: arquivo ${file} não existe no servidor de ftp`);
                return this.t.done();
            }

            this.t.print(`550: permissão negada para arquivo ${file}`);
            return this.t.done();
        }
    }

    async requestAction() {
        const action = await this.t.input(null, 'ftp>');
        switch (true) {
            case /^get/.test(action):
                await this.getFile(action.replace('get ', ''));
                await this.requestAction();
                return this.t.done();

            case /^put/.test(action):
                await this.putFile(action.replace('put ', ''));
                await this.requestAction();
                return this.t.done();

            case /^delete/.test(action):
                await this.delete(action.replace('delete ', ''));
                await this.requestAction();
                return this.t.done();

            case /^ls/.test(action):
                this.t.print('200: listando arquivos do diretorio atual do servidor de ftp')
                await this.t.executeCommand(`ls ${systemFtpPath}/${this.host}/${this.currentPath}`);
                await this.requestAction();
                return this.t.done();

            case /^pwd/.test(action):
                this.t.print('200: caminho atual do servidor de ftp')
                this.t.print(this.currentPath);
                await this.requestAction();
                return this.t.done();

            case /^lls/.test(action):
                this.t.print('local: listando arquivos do diretorio atual da sua maquina')
                await this.t.executeCommand('ls');
                await this.requestAction();
                return this.t.done();

            case /^lcd/.test(action):
                this.t.print('local: abrindo diretorio na sua maquina')
                await this.t.executeCommand(action.replace('lcd', 'cd'));
                await this.requestAction();
                return this.t.done();

            case /^lpwd/.test(action):
                this.t.print('local: caminho atual da sua maquina')
                await this.t.executeCommand('pwd');
                await this.requestAction();
                return this.t.done();

            case /^(\?|help)/.test(action):
                await this.help();
                await this.requestAction();
                return this.t.done();

            case /(quit|exit|bye)/.test(action):
                return this.t.done();

            default:
                this.t.print(`Comando ${action} é inválido, digite ? para obter ajuda.`)
                await this.requestAction();
                return this.t.done();
        }
    }
}

export default {
    ftp: async(t: TerminalApi, $0: string) => await (new FtpCommand(t, $0).executeCommand())
}