import { Progress, Challenge, createChallenge } from "./Challenge";
import TerminalApi from "../../terminal/TerminalApi";
import events from "../../core/events";
import { HARDWARE_CONNECT_FIXED_EVENT, CENT92_SETTINGS_AJUSTED } from "../terminalCommands/modules/ProjectManager";
import { changeScene } from "../actions";
import { Scene } from "../scene";

export const VerifyProjectModuleGoals = {
    FIX_HARDWARE: 'Corrigir comunicação com hardware.',
    FIX_SETTINGS: 'Corrigir configurações da centrifuga.'
};

const initialProgress: Progress = {
    title: 'Ligar a centrifuga',
    goals: {
        doing: VerifyProjectModuleGoals.FIX_HARDWARE,
        todo: [
            VerifyProjectModuleGoals.FIX_SETTINGS
        ],
        completed: []
    }
}

class VerifyProjectModule extends Challenge {
    constructor(t: TerminalApi) {
        super(t);
        this.setCurrentProgress(initialProgress);
        this.subscribeToEvents();
    }

    subscribeToEvents() {
        events.subscribe(HARDWARE_CONNECT_FIXED_EVENT, () => {
            if (!this.completeGoal(VerifyProjectModuleGoals.FIX_HARDWARE)) return;
            this.t.setTerminalHelp(`
                <h2>Primeiro objetivo concluido.</h2>

                <p>
                    Você concluiu seu primeiro objetivo. É isso aí!
                    Para ver os proximos objetivos, clique em "objetivos >>"
                    na barra de desafio.
                </p>
                <br />
                <p>
                    A qualquer momento que você tiver dúvidas de como
                    prosseguir, você pode clicar alí na barra de desafio
                    em "Ajuda".
                </p>

                <a data-close="true" class="command">Entendido</a>
            `);
            this.completeGoal(VerifyProjectModuleGoals.FIX_HARDWARE);
        });

        events.subscribe(CENT92_SETTINGS_AJUSTED, () => {
            if (!this.completeGoal(VerifyProjectModuleGoals.FIX_SETTINGS)) return;
            this.t.dispatch(changeScene(Scene.READ_IN_FUTURE_INTRODUCTION));
        });
    }

    helpCallback(): Promise<any> {
        if (this.getCurrentGoal() === VerifyProjectModuleGoals.FIX_HARDWARE) {
            this.t.setTerminalHelp(`
                <h2>Resolvendo o primeiro problema!</h2>

                <p>
                    Parece que tem alguma coisa estranha com a conexão do harware com o
                    controlador, o computador que você está utilizando.
                    Você precisa verificar por que o hardware não está conectando, você 
                    deve utilizar o project-manager para gerenciar o projeto.
                </p>

                <p>
                    Project Manager é o software criado por meu pai e aprimorado por
                    mim (quando eu ainda era de carne).
                </p>

                <a data-command="project-manager --help" class="command">project-manager</a>
                <a data-close="true" class="command">Entendi!</a>
            `);
        }

        if (this.getCurrentGoal() === VerifyProjectModuleGoals.FIX_SETTINGS) {
            this.t.setTerminalHelp(`
                <h2>Erro de configuração</h2>

                <p>
                    Parece que tem um erro de configuração que está impedindo de continuar a conexão com o 
                    hardware. Use o centctrl para gerenciar a centrifuga atomica.
                </p>

                <a data-command="centctrl" class="command">centctrl</a>
                <a data-close="true" class="command">Entendido</a>
            `)
        }


        return this.t.done();    
    }

    getName() {
        return 'verify_project_module'
    }
};

export const createVerifyProjectModuleChallenge = async(t: TerminalApi) => await createChallenge(VerifyProjectModule, t);